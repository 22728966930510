import { QueryResponse$1, useGql } from "./Hooks.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { WineBottlesResponse_$reflection } from "./Dto.fs.js";

export function useWineBottles() {
    let matchValue;
    const patternInput = useGql("{\r\n    wine_bottles(sort: \"invoice_name\") {\r\n      id\r\n      name\r\n      vintage\r\n      invoice_name\r\n      discontinued\r\n      domain {\r\n        name\r\n        country\r\n      }\r\n    }\r\n  }");
    const content = patternInput[1];
    if (patternInput[0] ? true : (content === "")) {
        return new QueryResponse$1(0, []);
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(content), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(WineBottlesResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return new QueryResponse$1(2, [parseResult.fields[0]]);
        }
        else {
            return new QueryResponse$1(1, [parseResult.fields[0]]);
        }
    }
}

