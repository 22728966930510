import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { singleton, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { class_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { map, isEmpty, empty, singleton as singleton_1, append, collect, delay, toArray } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { rangeDouble } from "./fable_modules/fable-library.4.3.0/Range.js";
import { join } from "./fable_modules/fable-library.4.3.0/String.js";

export function EmptyData() {
    return createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "is-light"], ["children", "There is no data."]]))));
}

export function LoadingElement() {
    return createElement("progress", createObj(Helpers_combineClasses("progress", singleton(["className", "is-small"]))));
}

export const KPIS_ENDPOINT = process.env["KPIS_ENDPOINT"] ? process.env["KPIS_ENDPOINT"] : '';

export const CMS_ENDPOINT = process.env["CMS_ENDPOINT"] ? process.env["CMS_ENDPOINT"] : '';

export const IS_OFFLINE = (process.env["IS_OFFLINE"] ? process.env["IS_OFFLINE"] : '') === "true";

export class Array$ {
    constructor() {
    }
}

export function Array$_$reflection() {
    return class_type("Common.Array", void 0, Array$);
}

export function Array_join(x, source) {
    return toArray(delay(() => collect((i) => append(singleton_1(source[i]), delay(() => ((i < (source.length - 1)) ? singleton_1(x) : empty()))), rangeDouble(0, 1, source.length - 1))));
}

export function String_join(separator, values) {
    return join(separator, values);
}

export function Result_defaultValue(value, res) {
    if (res.tag === 0) {
        return res.fields[0];
    }
    else {
        return value;
    }
}

export function DualUIState_map(whenEmpty, f, source) {
    if (isEmpty(source)) {
        return [whenEmpty()];
    }
    else {
        return map(f, source);
    }
}

export function DualUIState_apply(whenEmpty, f, source) {
    if (isEmpty(source)) {
        return whenEmpty();
    }
    else {
        return f(source);
    }
}

