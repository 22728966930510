import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { interpolate, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { KPIS_ENDPOINT } from "./Common.fs.js";
import { Http_post } from "./fable_modules/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { ignore } from "./fable_modules/fable-library.4.3.0/Async.js";

export function invalidateStartOfMonthInventory(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/invalidate/inventory/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function invalidateSnapshotBasedStartOfMonthInventory(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/invalidate/snapshot-based-inventory/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function invalidateMonthWarehouseMovements(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/invalidate/warehouse-movements/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function invalidateMonthPredeliveries(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/invalidate/predeliveries/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function reloadMonthInvoices(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/reload/invoices/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function reloadMonthSalesStats(year, month) {
    return singleton.Delay(() => {
        const url = toText(interpolate("%P()/reload/sales-stats/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton.Bind(Http_post(url, ""), (_arg) => singleton.Return(void 0));
    });
}

export function invalidateGlobalInventorySnapshotRepository() {
    return singleton.Delay(() => singleton.ReturnFrom(ignore(Http_post(`${KPIS_ENDPOINT}/invalidate/snapshots`, ""))));
}

export function reloadLosses() {
    return singleton.Delay(() => singleton.Bind(Http_post(`${KPIS_ENDPOINT}/reload/losses`, ""), (_arg) => singleton.Return(void 0)));
}

export function reloadPurchases() {
    return singleton.Delay(() => singleton.Bind(Http_post(`${KPIS_ENDPOINT}/reload/purchases`, ""), (_arg) => singleton.Return(void 0)));
}

