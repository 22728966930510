import { reverse, empty, cons, fold } from "../fable-library.4.3.0/List.js";
import { toString } from "../fable-library.4.3.0/Types.js";
import { join } from "../fable-library.4.3.0/String.js";

export function Helpers_extractClasses(xs) {
    return fold((tupledArg, x) => {
        const classes = tupledArg[0];
        const props = tupledArg[1];
        const matchValue = x;
        if (matchValue[0] === "className") {
            return [cons(toString(matchValue[1]), classes), props];
        }
        else {
            return [classes, cons(x, props)];
        }
    }, [empty(), empty()], reverse(xs));
}

export function Helpers_combineClasses(cn, xs) {
    const tupledArg = Helpers_extractClasses(xs);
    return cons(["className", join(" ", cons(cn, tupledArg[0]))], tupledArg[1]);
}

