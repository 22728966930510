import { subMonths, startOfMonth, format as format_2 } from "date-fns";
import { Record, Union } from "./fable_modules/fable-library.4.3.0/Types.js";
import { class_type, decimal_type, record_type, array_type, anonRecord_type, int64_type, int32_type, string_type, union_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { useGetQuery, QueryResponse$1, useGetQueryWithRefresh } from "./Hooks.fs.js";
import { compare as compare_1, substring, interpolate, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { EmptyData, DualUIState_apply, LoadingElement, KPIS_ENDPOINT } from "./Common.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { Invoice__FormatDescription, Invoice_$reflection, Loss_$reflection, ApiResponse$1_$reflection } from "./Dto.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useReact_useEffect_311B4086, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { comparePrimitives, stringHash, int64ToString, createObj } from "./fable_modules/fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { cons, map as map_2, exists, singleton, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { toArray as toArray_1, sumBy as sumBy_1, collect, map, empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { sum, sortBy, sortWith, map as map_1, collect as collect_1, reverse, mapIndexed, sumBy } from "./fable_modules/fable-library.4.3.0/Array.js";
import { equals, op_Addition, toInt64 } from "./fable_modules/fable-library.4.3.0/BigInt.js";
import { WarningMessage } from "./Components.fs.js";
import { singleton as singleton_2 } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { reloadMonthInvoices } from "./FastKpisClient.fs.js";
import { FSharpMap__get_Item, ofArray as ofArray_1, ofSeq } from "./fable_modules/fable-library.4.3.0/Map.js";
import { Array_groupBy } from "./fable_modules/fable-library.4.3.0/Seq2.js";
import { toNumber } from "./fable_modules/fable-library.4.3.0/Decimal.js";
import { equals as equals_1, today, compare, month as month_1, year as year_1, create } from "./fable_modules/fable-library.4.3.0/Date.js";
import { ofArray as ofArray_2, toArray } from "./fable_modules/fable-library.4.3.0/Set.js";
import { defaultOf } from "./fable_modules/Feliz.2.7.0/../fable-library.4.3.0/Util.js";
import { DisplayMode } from "./fable_modules/Feliz.Bulma.DateTimePicker.3.0.0/Extensions.fs.js";
import { DatePicker_dateTimePicker } from "./fable_modules/Feliz.Bulma.DateTimePicker.3.0.0/./DateTime.fs.js";

export const colorClasses = ["is-info", "is-primary", "is-warning", "is-success", "is-dark", "is-danger"];

export function formatMonth(dt) {
    return format_2(dt, "MMM yyyy");
}

export class BottleCostStrategy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Old", "Audit_2023_08"];
    }
}

export function BottleCostStrategy_$reflection() {
    return union_type("CwwDashboard.Sections.Sales.BottleCostStrategy", [], BottleCostStrategy, () => [[], []]);
}

export function BottleCostStrategy_get_Default() {
    return new BottleCostStrategy(1, []);
}

export function BottleCostStrategy_TryParse_Z721C83C5(s) {
    switch (s) {
        case "Old":
            return new BottleCostStrategy(0, []);
        case "Audit_2023_08":
            return new BottleCostStrategy(1, []);
        default:
            return void 0;
    }
}

export class InventoryJournalEntry extends Record {
    constructor(inventoryValues, totalValue, totalBottles) {
        super();
        this.inventoryValues = inventoryValues;
        this.totalValue = totalValue;
        this.totalBottles = (totalBottles | 0);
    }
}

export function InventoryJournalEntry_$reflection() {
    return record_type("CwwDashboard.Sections.Sales.InventoryJournalEntry", [], InventoryJournalEntry, () => [["inventoryValues", array_type(anonRecord_type(["bottle", string_type], ["count", int32_type], ["value", int64_type]))], ["totalValue", int64_type], ["totalBottles", int32_type]]);
}

export class SalesResponse extends Record {
    constructor(total, totalRevenue, totalProfit, inventoryJournalEntry) {
        super();
        this.total = (total | 0);
        this.totalRevenue = totalRevenue;
        this.totalProfit = totalProfit;
        this.inventoryJournalEntry = inventoryJournalEntry;
    }
}

export function SalesResponse_$reflection() {
    return record_type("CwwDashboard.Sections.Sales.SalesResponse", [], SalesResponse, () => [["total", int32_type], ["totalRevenue", decimal_type], ["totalProfit", decimal_type], ["inventoryJournalEntry", InventoryJournalEntry_$reflection()]]);
}

export function useSalesStats(year, month, beforeRefresh) {
    let matchValue;
    const patternInput = useGetQueryWithRefresh(toText(interpolate("%P()/sales-stats/month/%d%P()/%d%P()", [KPIS_ENDPOINT, year, month])), beforeRefresh);
    const responseBody = patternInput[1];
    const refresh = patternInput[2];
    if (patternInput[0] ? true : (responseBody === "")) {
        return [new QueryResponse$1(0, []), refresh];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(responseBody), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(ApiResponse$1_$reflection(SalesResponse_$reflection()))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return [new QueryResponse$1(2, [parseResult.fields[0]]), refresh];
        }
        else {
            return [new QueryResponse$1(1, [parseResult.fields[0]]), refresh];
        }
    }
}

function MiscActions(miscActionsInputProps) {
    let elems_1, elms;
    const journalEntries = miscActionsInputProps.journalEntries;
    const month = miscActionsInputProps.month;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const xs_27 = [createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-5 mt-5"], ["children", "Other"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-outlined"], ["onClick", (_arg) => {
        setActive(true);
    }], (elems_1 = [(elms = singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-book"])), [])), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("span", {
        children: ["Inventory Journal Transactions"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("modal", toList(delay(() => append(patternInput[0] ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elems_9, elms_4, elms_2, elems_4, elems_3, elms_1, elms_3;
        return singleton_1((elems_9 = [createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg_1) => {
            setActive(false);
        }])))), (elms_4 = ofArray([(elms_2 = singleton(createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray([["className", "is-flex is-align-items-center is-justify-content-space-between"], (elems_4 = ["Inventory Journal Transactions", createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-rounded"], ["onClick", (_arg_2) => {
            setActive(false);
        }], (elems_3 = [(elms_1 = singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-minus-circle"])), [])), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), createElement("span", {
            children: ["Close"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))))), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = toList(delay(() => append(singleton_1(createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-4"], ["children", formatMonth(month)]]))))), delay(() => {
            const recomputedTotalBottles = sumBy((x) => x.count, journalEntries.inventoryValues, {
                GetZero: () => 0,
                Add: (x_1, y) => (x_1 + y),
            }) | 0;
            const recomputedTotalValue = sumBy((x_2) => x_2.value, journalEntries.inventoryValues, {
                GetZero: () => (0n),
                Add: (x_3, y_1) => toInt64(op_Addition(x_3, y_1)),
            });
            return append((recomputedTotalBottles !== journalEntries.totalBottles) ? singleton_1(createElement(WarningMessage, {
                title: "Inconsistent bottle counts",
                children: `${recomputedTotalBottles} (recalculated) vs ${journalEntries.totalBottles} (from API)`,
            })) : empty(), delay(() => append(!equals(recomputedTotalValue, journalEntries.totalValue) ? singleton_1(createElement(WarningMessage, {
                title: "Inconsistent total value",
                children: `${recomputedTotalValue} (recalculated) vs ${journalEntries.totalValue} (from API)`,
            })) : empty(), delay(() => {
                let elems_6, children_5, children_3, children_9, children_13, children_11;
                return singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-narrow is-striped is-hoverable"], (elems_6 = [(children_5 = singleton((children_3 = ofArray([createElement("th", {
                    children: ["Bottle"],
                }), createElement("th", {
                    className: "has-text-right",
                    children: "Count",
                }), createElement("th", {
                    className: "has-text-right",
                    children: "Value",
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                })), (children_9 = toList(delay(() => map((entry) => {
                    const children_7 = ofArray([createElement("td", {
                        children: [entry.bottle],
                    }), createElement("td", {
                        className: "has-text-right",
                        children: entry.count,
                    }), createElement("td", {
                        className: "has-text-right",
                        children: int64ToString(entry.value),
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                    });
                }, journalEntries.inventoryValues))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_9)),
                })), (children_13 = singleton((children_11 = ofArray([createElement("th", {
                    children: ["Total"],
                }), createElement("th", {
                    className: "has-text-right",
                    children: journalEntries.totalBottles,
                }), createElement("th", {
                    className: "has-text-right",
                    children: int64ToString(journalEntries.totalValue),
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_11)),
                }))), createElement("tfoot", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_13)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))));
            }))));
        })))), createElement("section", {
            className: "modal-card-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))]), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))]));
    })))))))];
    return react.createElement(react.Fragment, {}, ...xs_27);
}

export function SalesDigestByBottle(salesDigestByBottleInputProps) {
    const updateRefreshSignal = salesDigestByBottleInputProps.updateRefreshSignal;
    const month = salesDigestByBottleInputProps.month;
    const year = salesDigestByBottleInputProps.year;
    const patternInput = useGetQueryWithRefresh(toText(interpolate("%P()/movements/month/%d%P()/%d%P()", [KPIS_ENDPOINT, year, month])), () => singleton_2.Delay(() => singleton_2.Bind(reloadMonthInvoices(year, month), () => {
        updateRefreshSignal();
        return singleton_2.Zero();
    })));
    const patternInput_1 = useSalesStats(year, month, () => singleton_2.Return(void 0));
    const xs_29 = toList(delay(() => append(singleton_1(createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-5"], ["children", Interop_reactApi.Children.toArray(["Sales performance"])]]))))), delay(() => {
        let matchValue, error, response, stats;
        return append((matchValue = patternInput_1[0], (matchValue.tag === 2) ? ((error = matchValue.fields[0], singleton_1(createElement("span", createObj(Helpers_combineClasses("tag", toList(delay(() => append(singleton_1(["className", "mb-5"]), delay(() => append(singleton_1(["style", {
            display: "block",
            maxWidth: ("calc(" + "80vw") + ")",
            height: "unset",
            whiteSpace: "pre-wrap",
        }]), delay(() => singleton_1(["children", `Couldn't fetch sales stats: (${(error.length > 100) ? (substring(error, 0, 100) + "...<TRUNCATED>") : error})`]))))))))))))) : ((matchValue.tag === 1) ? ((response = matchValue.fields[0], (stats = response.value, append(collect((matchValue_1) => {
            let elems_2, elems_1;
            const activePatternResult = matchValue_1;
            return singleton_1(createElement("div", createObj(Helpers_combineClasses("tags", ofArray([["className", "has-addons"], (elems_2 = [createElement(WarningMessage, {
                title: activePatternResult[0],
                children: createElement("ul", createObj(ofArray([["className", "mb-2"], (elems_1 = toList(delay(() => map((warning_1) => createElement("li", {
                    children: [warning_1.detail],
                }), activePatternResult[1]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))));
        }, ofSeq(Array_groupBy((warning) => warning.title, response.warnings, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }), {
            Compare: comparePrimitives,
        })), delay(() => {
            let elems_3, children_12, children_2, children, children_6, children_4, children_10, children_8;
            return append(singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-narrow is-striped is-hoverable"], (elems_3 = [(children_12 = ofArray([(children_2 = ofArray([(children = singleton(createElement("b", {
                children: ["Bottles sold"],
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), createElement("td", {
                className: "pl-4 has-text-right",
                children: stats.total,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_6 = ofArray([(children_4 = singleton(createElement("b", {
                children: ["Revenue"],
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), createElement("td", {
                className: "pl-4 has-text-right",
                children: `$${Math.round(toNumber(stats.totalRevenue))}`,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })), (children_10 = ofArray([(children_8 = singleton(createElement("b", {
                children: ["Profit"],
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            })), createElement("td", {
                className: "pl-4 has-text-right",
                children: `$${Math.round(toNumber(stats.totalProfit))}`,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))), delay(() => singleton_1(createElement(MiscActions, {
                month: create(year, month, 1),
                journalEntries: stats.inventoryJournalEntry,
            }))));
        }))))) : singleton_1(createElement(LoadingElement, null)))), delay(() => {
            let elems_4, children_14;
            return append(singleton_1(createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-5 mt-5"], (elems_4 = [(children_14 = ofArray(["Bottle sales", createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "ml-2 is-text"], ["onClick", (_arg_1) => {
                patternInput[2]();
                patternInput_1[1]();
            }], ["className", "is-small"], ["children", "reload"]]))))]), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_14)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))))), delay(() => {
                let matchValue_2;
                if (patternInput[0]) {
                    return singleton_1(createElement(LoadingElement, null));
                }
                else {
                    let matchValue_3;
                    try {
                        matchValue_3 = (new FSharpResult$2(0, [(matchValue_2 = SimpleJson_tryParse(patternInput[1]), (matchValue_2 != null) ? Convert_fromJson(matchValue_2, createTypeInfo(array_type(anonRecord_type(["bottle", string_type], ["stocks", array_type(anonRecord_type(["count", int32_type], ["purchaseDate", class_type("System.DateTime")]))])))) : (() => {
                            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                        })())]));
                    }
                    catch (ex) {
                        matchValue_3 = (new FSharpResult$2(1, [ex.message]));
                    }
                    if (matchValue_3.tag === 0) {
                        return singleton_1(DualUIState_apply(() => createElement(EmptyData, null), (salesMovements_1) => {
                            let elems_8;
                            const purchaseDatesColors = ofArray_1(mapIndexed((i, date) => [date, colorClasses[(((year_1(date) * 100) + month_1(date)) - 3) % colorClasses.length]], reverse(toArray(ofArray_2(collect_1((x_2) => map_1((stock) => stock.purchaseDate, x_2.stocks), salesMovements_1), {
                                Compare: compare,
                            })))), {
                                Compare: compare,
                            });
                            return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-narrow is-hoverable is-striped"], (elems_8 = toList(delay(() => {
                                let children_16;
                                const productCounts = sumBy((mvt) => sumBy((x_5) => x_5.count, mvt.stocks, {
                                    GetZero: () => 0,
                                    Add: (x_6, y_4) => (x_6 + y_4),
                                }), salesMovements_1, {
                                    GetZero: () => 0,
                                    Add: (x_7, y_5) => (x_7 + y_5),
                                }) | 0;
                                return append(singleton_1((children_16 = toList(delay(() => map((bottleStock) => {
                                    let elems_7, elems_6;
                                    return createElement("tr", createObj(ofArray([["key", bottleStock.bottle], (elems_7 = [createElement("td", {
                                        children: [`${bottleStock.bottle}`],
                                    }), createElement("td", createObj(ofArray([["className", "has-text-right"], (elems_6 = toList(delay(() => collect((p) => {
                                        let elems_5;
                                        return (bottleStock.stocks.length < 2) ? singleton_1(createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", `${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)} is-rounded`], ["children", format_2(p.purchaseDate, "MMM yy")]]))))) : singleton_1(createElement("div", createObj(Helpers_combineClasses("tags", ofArray([["className", "has-addons mb-0 mr-2"], (elems_5 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", `is-rounded ${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)}`], ["children", format_2(p.purchaseDate, "MMM yy")]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", `is-rounded ${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)} has-text-weight-bold is-light`], ["children", `${p.count}`]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))));
                                    }, bottleStock.stocks))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("td", {
                                        children: [`${sumBy((s) => s.count, bottleStock.stocks, {
                                            GetZero: () => 0,
                                            Add: (x_8, y_6) => (x_8 + y_6),
                                        })}`],
                                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
                                }, salesMovements_1))), createElement("tbody", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                                }))), delay(() => {
                                    let children_20, children_18;
                                    return singleton_1((children_20 = singleton((children_18 = ofArray([createElement("th", {
                                        children: ["Total"],
                                    }), createElement("th", {
                                        children: [defaultOf()],
                                    }), createElement("th", {
                                        className: "has-text-right",
                                        children: productCounts,
                                    })]), createElement("tr", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                                    }))), createElement("tfoot", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children_20)),
                                    })));
                                }));
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))));
                        }, sortWith((mvt1, mvt2) => compare_1(mvt1.bottle, mvt2.bottle), matchValue_3.fields[0])));
                    }
                    else {
                        return empty();
                    }
                }
            }));
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_29);
}

function Losses(lossesInputProps) {
    let matchValue;
    const month = lossesInputProps.month;
    const year = lossesInputProps.year;
    const patternInput = useGetQuery(toText(interpolate("%P()/losses/month/%d%P()/%d%P()", [KPIS_ENDPOINT, year, month])));
    if (patternInput[0]) {
        return createElement(LoadingElement, null);
    }
    else {
        let lossesResponse;
        try {
            lossesResponse = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(array_type(Loss_$reflection()))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            lossesResponse = (new FSharpResult$2(1, [ex.message]));
        }
        if (lossesResponse.tag === 1) {
            return defaultOf();
        }
        else {
            const losses = lossesResponse.fields[0];
            const xs_6 = [createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-5"], ["children", "Losses"]])))), DualUIState_apply(() => createElement(EmptyData, null), (_arg) => {
                let elems, children_2, children_6, children_4;
                return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-narrow is-striped is-hoverable"], (elems = [(children_2 = toList(delay(() => map((loss) => {
                    const children = ofArray([createElement("td", {
                        children: [format_2(loss.Date, "MMM do")],
                    }), createElement("td", {
                        children: [loss.Product],
                    }), createElement("td", {
                        className: "pl-4 has-text-right",
                        children: loss.Quantity,
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    });
                }, losses))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_6 = singleton((children_4 = ofArray([createElement("th", {
                    children: ["Total"],
                }), createElement("th", {
                    children: [""],
                }), createElement("th", {
                    className: "pl-4",
                    children: sumBy_1((x_2) => x_2.Quantity, losses, {
                        GetZero: () => 0,
                        Add: (x_3, y_1) => (x_3 + y_1),
                    }),
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))), createElement("tfoot", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
            }, sortBy((x) => x.Date, losses, {
                Compare: compare,
            }))];
            return react.createElement(react.Fragment, {}, ...xs_6);
        }
    }
}

export function InvoiceList(invoiceListInputProps) {
    let matchValue;
    const refreshSignal = invoiceListInputProps.refreshSignal;
    const month = invoiceListInputProps.month;
    const year = invoiceListInputProps.year;
    const patternInput = useGetQueryWithRefresh(toText(interpolate("%P()/invoices/month/%d%P()/%d%P()", [KPIS_ENDPOINT, year, month])), () => singleton_2.Return(void 0));
    useReact_useEffect_311B4086(patternInput[2], [refreshSignal]);
    if (patternInput[0]) {
        return createElement(LoadingElement, null);
    }
    else {
        let invoicesResponse;
        try {
            invoicesResponse = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(array_type(Invoice_$reflection()))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            invoicesResponse = (new FSharpResult$2(1, [ex.message]));
        }
        if (invoicesResponse.tag === 0) {
            const invoices = invoicesResponse.fields[0];
            const totalSales = sum(toArray_1(delay(() => collect((ivc) => map((ivcItem) => ivcItem.Quantity, ivc.Items), invoices))), {
                GetZero: () => 0,
                Add: (x, y) => (x + y),
            }) | 0;
            const xs_18 = [createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-5"], ["children", "Detailed Invoices"]])))), DualUIState_apply(() => createElement(EmptyData, null), (_arg_1) => {
                let elems_3, children_2, children, children_16, children_20, children_18;
                return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-fullwidth is-hoverable is-narrow"], (elems_3 = [(children_2 = singleton((children = ofArray([createElement("th", {
                    children: ["Invoice N°"],
                }), createElement("th", {
                    children: ["Details"],
                }), createElement("th", {
                    className: "has-text-right",
                    children: "Subtotal",
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_16 = toList(delay(() => {
                    let currentSub = 0;
                    return collect((ivc_1) => {
                        let children_14, elems, elems_2, children_4, elems_1, children_8, children_12, children_10;
                        const invoiceItemCount = sumBy((item) => item.Quantity, ivc_1.Items, {
                            GetZero: () => 0,
                            Add: (x_1, y_1) => (x_1 + y_1),
                        }) | 0;
                        currentSub = ((currentSub + invoiceItemCount) | 0);
                        return singleton_1((children_14 = ofArray([createElement("td", createObj(ofArray([["className", "has-text-right"], (elems = [createElement("a", {
                            href: ivc_1.ViewUrl,
                            target: "_blank",
                            children: ivc_1.InvoiceNumber,
                        }), createElement("br", {}), createElement("span", {
                            className: "is-size-7",
                            children: format_2(ivc_1.InvoiceDate, "(do MMM)"),
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", createObj(singleton((elems_2 = [(children_4 = singleton(Invoice__FormatDescription(ivc_1)), createElement("p", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                        })), createElement("br", {}), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-fullwidth is-size-7 is-striped"], (elems_1 = [(children_8 = toList(delay(() => map((ivcItem_1) => {
                            const children_6 = ofArray([createElement("td", {
                                children: [ivcItem_1.ProductName],
                            }), createElement("td", {
                                className: "has-text-right",
                                children: ivcItem_1.Quantity,
                            })]);
                            return createElement("tr", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                            });
                        }, ivc_1.Items))), createElement("tbody", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                        })), (children_12 = singleton((children_10 = ofArray([createElement("th", {
                            children: [""],
                        }), createElement("th", {
                            className: "has-text-right",
                            children: invoiceItemCount,
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                        }))), createElement("tfoot", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))), createElement("td", {
                            style: {
                                verticalAlign: "bottom",
                                textAlign: "right",
                            },
                            children: `${currentSub}`,
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                        })));
                    }, invoices);
                })), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                })), (children_20 = singleton((children_18 = ofArray([createElement("th", {
                    className: "has-text-right",
                    children: toText(interpolate("%d%P()", [invoices.length])),
                }), createElement("th", {
                    children: [""],
                }), createElement("th", {
                    className: "has-text-right",
                    children: toText(interpolate("%d%P()", [totalSales])),
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                }))), createElement("tfoot", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_20)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
            }, invoices)];
            return react.createElement(react.Fragment, {}, ...xs_18);
        }
        else {
            return defaultOf();
        }
    }
}

export function SalesSectionContent(salesSectionContentInputProps) {
    let elms, children, children_2;
    const month = salesSectionContentInputProps.month;
    const year = salesSectionContentInputProps.year;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const state = patternInput[0] | 0;
    const xs_3 = [(elms = ofArray([(children = singleton(createElement(SalesDigestByBottle, {
        year: year,
        month: month,
        updateRefreshSignal: () => {
            patternInput[1](state + 1);
        },
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), (children_2 = singleton(createElement(Losses, {
        year: year,
        month: month,
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement(InvoiceList, {
        year: year,
        month: month,
        refreshSignal: state,
    })];
    return react.createElement(react.Fragment, {}, ...xs_3);
}

export function SalesSection() {
    let elems_4, elms_2, elems_1, elms_1, safeProps, arg_1, name_9, props_8, arg, name_8, props_7;
    const monthM = startOfMonth(today());
    const monthList = [monthM, subMonths(monthM, 1), subMonths(monthM, 2), subMonths(monthM, 3)];
    const patternInput = useFeliz_React__React_useState_Static_1505(monthM);
    const setMonth = patternInput[1];
    const month = patternInput[0];
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray([["className", "is-primary"], (elems_4 = [createElement("div", {
        className: "message-header",
        children: Interop_reactApi.Children.toArray(["Sales of the month"]),
    }), (elms_2 = ofArray([createElement("div", createObj(Helpers_combineClasses("buttons", ofArray([["className", "has-addons"], (elems_1 = toList(delay(() => map((m) => createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton_1(["key", format_2(m, "yyyy-MM")]), delay(() => append(singleton_1(["className", "is-small is-rounded"]), delay(() => append(equals_1(m, month) ? singleton_1(["className", "is-active is-primary"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg) => {
        setMonth(m);
    }]), delay(() => singleton_1(["children", formatMonth(m)])))))))))))))), monthList))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), (elms_1 = ofArray([createElement("label", {
        className: "label",
        children: "Month picker",
    }), (safeProps = ((arg_1 = ["clearLabel", "Clear"], (name_9 = arg_1[0], (props_8 = ((arg = ["displayMode", new DisplayMode(0, [])], (name_8 = arg[0], (props_7 = ofArray([["dateOnly", true], ["onDateSelected", (d) => {
        if (d != null) {
            const dt = d;
            setMonth(create(year_1(dt), month_1(dt), 1));
        }
    }], ["isRange", false], ["clearLabel", "Select custom month"], ["closeOnSelect", true]]), exists((tupledArg) => (tupledArg[0] === name_8), map_2((value_1_1) => value_1_1, props_7)) ? props_7 : cons([name_8, arg[1]], props_7))))), exists((tupledArg_1) => (tupledArg_1[0] === name_9), map_2((value_1_2) => value_1_2, props_8)) ? props_8 : cons([name_9, arg_1[1]], props_8))))), createElement(DatePicker_dateTimePicker, {
        p: createObj(safeProps),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-4 mt-6"], ["children", formatMonth(month)]])))), createElement(SalesSectionContent, {
        year: year_1(month),
        month: month_1(month),
    })]), createElement("div", {
        className: "message-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
}

