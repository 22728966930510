import { Union, Record } from "../fable-library.4.3.0/Types.js";
import { union_type, record_type, option_type, class_type, bool_type, string_type } from "../fable-library.4.3.0/Reflection.js";
import { value as value_96, toArray, map, defaultArg } from "../fable-library.4.3.0/Option.js";
import { equals, createObj, int32ToString } from "../fable-library.4.3.0/Util.js";
import { month as month_1, year as year_1, create, maxValue, minValue, addMonths, utcNow, compare, add, timeOfDay, date as date_26, day as day_1 } from "../fable-library.4.3.0/Date.js";
import { parse, isSameYear as isSameYear_1, isToday, isSameDay, isWithinInterval, startOfDay, isValid, isSameMonth as isSameMonth_1, addDays, startOfMonth, startOfWeek, format as format_5 } from "date-fns";
import { Layout_wrapper, Hook_useOnClickOutside, Html_divClassed } from "./Extensions.fs.js";
import { cons, append, item, map as map_1, ofArray, singleton } from "../fable-library.4.3.0/List.js";
import { TimePicker_timePickerMinutes, TimePicker_toTimeSpans, TimePicker_timePickerHours, TimePicker_toHrsMins, TimePicker_toFormattedTime } from "./Time.fs.js";
import { createElement } from "react";
import React from "react";
import { op_PlusPlus } from "../Feliz.Bulma.3.0.0/Operators.fs.js";
import { Interop_reactApi } from "../Feliz.2.7.0/./Interop.fs.js";
import { collect, map as map_2, empty, singleton as singleton_1, append as append_1, delay, iterate, toList } from "../fable-library.4.3.0/Seq.js";
import { rangeDouble } from "../fable-library.4.3.0/Range.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useElementRef } from "../Feliz.2.7.0/React.fs.js";
import { printf, toText, join } from "../fable-library.4.3.0/String.js";
import { defaultOf } from "../Feliz.2.7.0/../fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "../Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";

export class DatePicker_TextInputEventArgs extends Record {
    constructor(InputText, IsValid, DateTime) {
        super();
        this.InputText = InputText;
        this.IsValid = IsValid;
        this.DateTime = DateTime;
    }
}

export function DatePicker_TextInputEventArgs_$reflection() {
    return record_type("Feliz.Bulma.DatePicker.TextInputEventArgs", [], DatePicker_TextInputEventArgs, () => [["InputText", string_type], ["IsValid", bool_type], ["DateTime", option_type(class_type("System.DateTime"))]]);
}

class DatePicker_DateTimeValue extends Record {
    constructor(Date$, Time) {
        super();
        this.Date = Date$;
        this.Time = Time;
    }
}

function DatePicker_DateTimeValue_$reflection() {
    return record_type("Feliz.Bulma.DatePicker.DateTimeValue", [], DatePicker_DateTimeValue, () => [["Date", option_type(class_type("System.DateTime"))], ["Time", option_type(class_type("System.TimeSpan"))]]);
}

export class DatePicker_ParseOptsImpl {
    constructor() {
        this["additionalDigits@"] = 0;
    }
    get additionalDigits() {
        const __ = this;
        return __["additionalDigits@"] | 0;
    }
    set additionalDigits(v) {
        const __ = this;
        __["additionalDigits@"] = (v | 0);
    }
}

export function DatePicker_ParseOptsImpl_$reflection() {
    return class_type("Feliz.Bulma.DatePicker.ParseOptsImpl", void 0, DatePicker_ParseOptsImpl);
}

export function DatePicker_ParseOptsImpl_$ctor() {
    return new DatePicker_ParseOptsImpl();
}

function DatePicker_datePickerSelection(l, d) {
    const day = defaultArg(map(int32ToString, map(day_1, d.Date)), "-");
    const yearMonth = defaultArg(map((x_1) => {
        const formatOptions = {};
        formatOptions.locale = l;
        return format_5(x_1, "MMMM yyyy", formatOptions);
    }, d.Date), "");
    const dayText = defaultArg(map((x_2) => {
        const formatOptions_1 = {};
        formatOptions_1.locale = l;
        return format_5(x_2, "E", formatOptions_1);
    }, d.Date), "");
    return Html_divClassed("datetimepicker-selection-wrapper", ofArray([Html_divClassed("datetimepicker-selection-day", singleton(day)), Html_divClassed("datetimepicker-selection-date", ofArray([Html_divClassed("datetimepicker-selection-month", singleton(yearMonth)), Html_divClassed("datetimepicker-selection-weekday", singleton(dayText))]))]));
}

function DatePicker_datePickerTimeSelection(l, d) {
    let elems;
    const time = defaultArg(map(TimePicker_toFormattedTime, d.Time), "-");
    return Html_divClassed("datetimepicker-selection-time", ofArray([Html_divClassed("datetimepicker-selection-time-icon", singleton(createElement("figure", createObj(ofArray([["style", {
        opacity: 0.5,
    }], ["className", "image is-16x16"], (elems = [createElement("i", createObj(singleton(op_PlusPlus(["className", "far fa-clock"], ["className", "has-text-grey"]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))), Html_divClassed("datetimepicker-selection-hour", singleton(time))]));
}

function DatePicker_weekRange(l, d) {
    const fstDate = startOfWeek(startOfMonth(d), {
        locale: l,
    });
    return map_1((d_1) => {
        const formatOptions = {};
        formatOptions.locale = l;
        return format_5(d_1, "EEE", formatOptions);
    }, map_1((i) => addDays(fstDate, i), toList(rangeDouble(0, 1, 6))));
}

function DatePicker_monthRange(l, d) {
    const fstDate = startOfWeek(startOfMonth(d), {
        locale: l,
    });
    const dates = map_1((x) => addDays(fstDate, x), toList(rangeDouble(0, 1, 34)));
    if (isSameMonth_1(addDays(item(34, dates), 1), d)) {
        return append(dates, map_1((x_1) => addDays(fstDate, x_1), toList(rangeDouble(35, 1, 41))));
    }
    else {
        return dates;
    }
}

class DatePicker_EditMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Date", "Month", "Year"];
    }
}

function DatePicker_EditMode_$reflection() {
    return union_type("Feliz.Bulma.DatePicker.EditMode", [], DatePicker_EditMode, () => [[], [], []]);
}

function DatePicker_EditMode__IsDateMode(x) {
    return equals(x, new DatePicker_EditMode(0, []));
}

function DatePicker_EditMode__IsMonthMode(x) {
    return equals(x, new DatePicker_EditMode(1, []));
}

function DatePicker_EditMode__IsYearMode(x) {
    return equals(x, new DatePicker_EditMode(2, []));
}

const DatePicker_DateTimeValueModule_empty = new DatePicker_DateTimeValue(void 0, void 0);

function DatePicker_DateTimeValueModule_date(dtv) {
    return dtv.Date;
}

function DatePicker_DateTimeValueModule_fromDateTime(d) {
    if (isValid(d) === true) {
        return new DatePicker_DateTimeValue(date_26(d), timeOfDay(d));
    }
    else {
        return DatePicker_DateTimeValueModule_empty;
    }
}

function DatePicker_DateTimeValueModule_tryToDateTime(dateOnly, dtv) {
    const matchValue = dtv.Date;
    const matchValue_1 = dtv.Time;
    let matchResult, d, t, d_1;
    if (dateOnly) {
        if (matchValue != null) {
            matchResult = 1;
            d_1 = matchValue;
        }
        else {
            matchResult = 2;
        }
    }
    else if (matchValue != null) {
        if (matchValue_1 != null) {
            matchResult = 0;
            d = matchValue;
            t = matchValue_1;
        }
        else {
            matchResult = 2;
        }
    }
    else {
        matchResult = 2;
    }
    switch (matchResult) {
        case 0:
            return add(date_26(d), t);
        case 1:
            return date_26(d_1);
        default:
            return void 0;
    }
}

function DatePicker_DateTimeValueModule_applyDate(dtv, d) {
    return new DatePicker_DateTimeValue(date_26(d), dtv.Time);
}

function DatePicker_DateTimeValueModule_applyTime(dtv, t) {
    return new DatePicker_DateTimeValue(dtv.Date, t);
}

function DatePicker_DateTimeValueModule_withoutDate(dtv) {
    return new DatePicker_DateTimeValue(void 0, dtv.Time);
}

function DatePicker_DateTimeValueModule_isComplete(dateOnly, dtv) {
    if (dateOnly) {
        return true;
    }
    else if ((dtv.Date != null) && (dtv.Time != null)) {
        return true;
    }
    else {
        return equals(dtv, DatePicker_DateTimeValueModule_empty);
    }
}

class DatePicker_SelectedValue extends Record {
    constructor(From, To) {
        super();
        this.From = From;
        this.To = To;
    }
}

function DatePicker_SelectedValue_$reflection() {
    return record_type("Feliz.Bulma.DatePicker.SelectedValue", [], DatePicker_SelectedValue, () => [["From", DatePicker_DateTimeValue_$reflection()], ["To", DatePicker_DateTimeValue_$reflection()]]);
}

function DatePicker_SelectedValueModule_fromRangeValue(rv) {
    const value = new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_empty, DatePicker_DateTimeValueModule_empty);
    return defaultArg(map((tupledArg) => (new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_fromDateTime(tupledArg[0]), DatePicker_DateTimeValueModule_fromDateTime(tupledArg[1]))), rv), value);
}

function DatePicker_SelectedValueModule_ensureDatesSort(sv) {
    const matchValue = sv.From;
    const matchValue_1 = sv.To;
    let matchResult, d1, d2;
    if (matchValue.Date != null) {
        if (matchValue_1.Date != null) {
            matchResult = 0;
            d1 = matchValue.Date;
            d2 = matchValue_1.Date;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            if (compare(d1, d2) > 0) {
                return new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyDate(sv.From, d2), DatePicker_DateTimeValueModule_applyDate(sv.To, d1));
            }
            else {
                return sv;
            }
        default:
            return sv;
    }
}

function DatePicker_SelectedValueModule_isRangeComplete(dateOnly, sv) {
    if (DatePicker_DateTimeValueModule_isComplete(dateOnly, sv.From)) {
        return DatePicker_DateTimeValueModule_isComplete(dateOnly, sv.To);
    }
    else {
        return false;
    }
}

function DatePicker_SelectedValueModule_getRangeValue(dateOnly, sv) {
    const matchValue = DatePicker_DateTimeValueModule_tryToDateTime(dateOnly, sv.From);
    const matchValue_1 = DatePicker_DateTimeValueModule_tryToDateTime(dateOnly, sv.To);
    let matchResult, e, s;
    if (matchValue != null) {
        if (matchValue_1 != null) {
            matchResult = 0;
            e = matchValue_1;
            s = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return [s, e];
        default:
            return void 0;
    }
}

function DatePicker_SelectedValueModule_applyDateSelectionOnRange(sv, d) {
    let matchValue, matchValue_1;
    return DatePicker_SelectedValueModule_ensureDatesSort((matchValue = sv.From, (matchValue_1 = sv.To, (matchValue.Date != null) ? ((matchValue_1.Date == null) ? (new DatePicker_SelectedValue(sv.From, DatePicker_DateTimeValueModule_applyDate(sv.To, d))) : (new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyDate(matchValue, d), DatePicker_DateTimeValueModule_withoutDate(matchValue_1)))) : (new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyDate(matchValue, d), DatePicker_DateTimeValueModule_withoutDate(matchValue_1))))));
}

function DatePicker_SelectedValueModule_applyTimeSelectionOnRange(sv, s, e) {
    return new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyTime(sv.From, s), DatePicker_DateTimeValueModule_applyTime(sv.To, e));
}

const DatePicker_SelectedValueModule_empty = new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_empty, DatePicker_DateTimeValueModule_empty);

function DatePicker_SelectedValueModule_fromSingleValue(s) {
    return defaultArg(map((x) => (new DatePicker_SelectedValue(x, x)), map(DatePicker_DateTimeValueModule_fromDateTime, s)), DatePicker_SelectedValueModule_empty);
}

function DatePicker_SelectedValueModule_isSingleValueComplete(dateOnly, sv) {
    return DatePicker_DateTimeValueModule_isComplete(dateOnly, sv.From);
}

function DatePicker_SelectedValueModule_getSingleValue(dateOnly, sv) {
    return DatePicker_DateTimeValueModule_tryToDateTime(dateOnly, sv.From);
}

function DatePicker_SelectedValueModule_applyDateSelectionOnSingle(sv, d) {
    return new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyDate(sv.From, d), DatePicker_DateTimeValueModule_empty);
}

function DatePicker_SelectedValueModule_applyTimeSelectionOnSingle(sv, s, _arg) {
    return new DatePicker_SelectedValue(DatePicker_DateTimeValueModule_applyTime(sv.From, s), DatePicker_DateTimeValueModule_empty);
}

export function DatePicker_dateTimePicker(datePicker_dateTimePickerInputProps) {
    let matchValue_19, elems_10, elems_9;
    const p = datePicker_dateTimePickerInputProps.p;
    const myRef = useReact_useElementRef();
    const defaultValue = p.isRange ? DatePicker_SelectedValueModule_fromRangeValue(p.defaultRangeValue) : DatePicker_SelectedValueModule_fromSingleValue(p.defaultValue);
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const valueFromInput = patternInput[0];
    const setValueFromInput = patternInput[1];
    let patternInput_2;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultValue);
    patternInput_2 = [patternInput_1[0], (x) => {
        setValueFromInput(void 0);
        patternInput_1[1](x);
    }];
    const value = patternInput_2[0];
    const setValue = patternInput_2[1];
    let patternInput_5;
    const matchValue = p.displayMode;
    if (matchValue.tag === 1) {
        const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
        patternInput_5 = [true, (value_1) => {
        }];
    }
    else {
        const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
        const displayed = patternInput_3[0];
        patternInput_5 = [displayed, (v_1) => {
            let matchResult;
            if (displayed) {
                if (v_1) {
                    matchResult = 2;
                }
                else {
                    matchResult = 0;
                }
            }
            else if (v_1) {
                matchResult = 1;
            }
            else {
                matchResult = 2;
            }
            switch (matchResult) {
                case 0: {
                    iterate((handler) => {
                        handler();
                    }, toArray(p.onHide));
                    break;
                }
                case 1: {
                    iterate((handler_1) => {
                        handler_1();
                    }, toArray(p.onShow));
                    break;
                }
            }
            patternInput_3[1](v_1);
        }];
    }
    const setIsDisplayed = patternInput_5[1];
    let patternInput_6;
    const matchValue_2 = value.From;
    patternInput_6 = ((matchValue_2.Date != null) ? useFeliz_React__React_useState_Static_1505(startOfMonth(matchValue_2.Date)) : useFeliz_React__React_useState_Static_1505(startOfMonth(utcNow())));
    const setCurrentMonth = patternInput_6[1];
    const currentMonth = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(new DatePicker_EditMode(0, []));
    const setEditMode = patternInput_7[1];
    const editMode = patternInput_7[0];
    Hook_useOnClickOutside(myRef, (_arg) => {
        setIsDisplayed(false);
    });
    const updateDate = (s_1) => {
        setValue(s_1);
        if ((p.isRange && DatePicker_SelectedValueModule_isRangeComplete(p.dateOnly, s_1)) && (p.onDateRangeSelected != null)) {
            value_96(p.onDateRangeSelected)(DatePicker_SelectedValueModule_getRangeValue(p.dateOnly, s_1));
        }
        if ((!p.isRange && DatePicker_SelectedValueModule_isSingleValueComplete(p.dateOnly, s_1)) && (p.onDateSelected != null)) {
            value_96(p.onDateSelected)(DatePicker_SelectedValueModule_getSingleValue(p.dateOnly, s_1));
        }
    };
    const onCurrentMonthSelected = (d) => {
        setEditMode(new DatePicker_EditMode(0, []));
        setCurrentMonth(d);
    };
    const onDateSelected = (d_1) => {
        let s_2, matchValue_3, matchValue_4, matchValue_5, matchValue_6;
        updateDate((s_2 = (p.isRange ? DatePicker_SelectedValueModule_applyDateSelectionOnRange(value, d_1) : DatePicker_SelectedValueModule_applyDateSelectionOnSingle(value, d_1)), ((matchValue_3 = p.closeOnSelect, (matchValue_4 = p.dateOnly, (matchValue_5 = p.isRange, (matchValue_6 = p.displayMode, matchValue_3 ? (matchValue_4 ? (matchValue_5 ? ((matchValue_6.tag === 0) ? (((s_2.From.Date != null) && (s_2.To.Date != null)) ? setIsDisplayed(false) : void 0) : void 0) : ((matchValue_6.tag === 0) ? setIsDisplayed(false) : void 0)) : void 0) : void 0)))), s_2)));
    };
    const onTimeSelected = (tupledArg) => {
        let tupledArg_1, tupledArg_2;
        const f = tupledArg[0];
        const t = tupledArg[1];
        updateDate(p.isRange ? ((tupledArg_1 = [f, t], DatePicker_SelectedValueModule_applyTimeSelectionOnRange(value, tupledArg_1[0], tupledArg_1[1]))) : ((tupledArg_2 = [f, t], DatePicker_SelectedValueModule_applyTimeSelectionOnSingle(value, tupledArg_2[0], tupledArg_2[1]))));
    };
    let patternInput_8;
    const tupledArg_3 = [defaultArg(value.From.Time, 0), defaultArg(value.To.Time, 0)];
    patternInput_8 = TimePicker_toHrsMins(tupledArg_3[0], tupledArg_3[1]);
    const sm = patternInput_8[1] | 0;
    const sh = patternInput_8[0] | 0;
    const em = patternInput_8[3] | 0;
    const eh = patternInput_8[2] | 0;
    const body = ofArray([Html_divClassed("datetimepicker-container", toList(delay(() => append_1(singleton_1(Html_divClassed("datetimepicker-header " + (p.dateOnly ? "is-date-only" : ""), toList(delay(() => {
        let elems;
        return append_1(singleton_1(Html_divClassed("datetimepicker-selection-details", ofArray([Html_divClassed("datetimepicker-selection-from", singleton(p.dateFromLabel)), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("datetimepicker-selection-start"), delay(() => (!p.isRange ? singleton_1("is-centered") : empty()))))))], (elems = toList(delay(() => append_1(singleton_1(DatePicker_datePickerSelection(p.locale, value.From)), delay(() => (!p.dateOnly ? singleton_1(DatePicker_datePickerTimeSelection(p.locale, value.From)) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]))), delay(() => (p.isRange ? singleton_1(Html_divClassed("datetimepicker-selection-details", ofArray([Html_divClassed("datetimepicker-selection-to", singleton(p.dateToLabel)), Html_divClassed("datetimepicker-selection-end", toList(delay(() => append_1(singleton_1(DatePicker_datePickerSelection(p.locale, value.To)), delay(() => (!p.dateOnly ? singleton_1(DatePicker_datePickerTimeSelection(p.locale, value.To)) : empty()))))))]))) : empty())));
    })))), delay(() => {
        let elems_1, elems_2, locale, formatOptions, elems_3, locale_1, formatOptions_1, elems_4, elems_6, elems_7, elems_8;
        return append_1(singleton_1(Html_divClassed("datepicker", ofArray([Html_divClassed("datepicker-nav", ofArray([createElement("button", createObj(ofArray([["type", "button"], ["className", "datepicker-nav-previous button is-small is-text"], (elems_1 = [createElement("i", {
            className: "fas fa-chevron-left",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["onClick", (_arg_2) => {
            onCurrentMonthSelected(startOfDay(startOfMonth(addMonths(currentMonth, -1))));
        }]]))), Html_divClassed("datepicker-nav-month-year", ofArray([createElement("div", createObj(ofArray([["className", "datepicker-nav-month"], ["onClick", (_arg_3) => {
            setEditMode(new DatePicker_EditMode(1, []));
        }], (elems_2 = [(locale = p.locale, (formatOptions = {}, (formatOptions.locale = locale, format_5(currentMonth, "LLLL", formatOptions))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", {
            dangerouslySetInnerHTML: {
                __html: "&nbsp;",
            },
        }), createElement("div", createObj(ofArray([["className", "datepicker-nav-year"], ["onClick", (_arg_4) => {
            setEditMode(new DatePicker_EditMode(2, []));
        }], (elems_3 = [(locale_1 = p.locale, (formatOptions_1 = {}, (formatOptions_1.locale = locale_1, format_5(currentMonth, "yyyy", formatOptions_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))])), createElement("button", createObj(ofArray([["type", "button"], ["className", "datepicker-nav-next button is-small is-text"], (elems_4 = [createElement("i", {
            className: "fas fa-chevron-right",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]), ["onClick", (_arg_5) => {
            onCurrentMonthSelected(startOfDay(startOfMonth(addMonths(currentMonth, 1))));
        }]])))])), Html_divClassed("datepicker-body", ofArray([createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("datepicker-dates"), delay(() => (DatePicker_EditMode__IsDateMode(editMode) ? singleton_1("is-active") : empty()))))))], (elems_6 = [Html_divClassed("datepicker-weekdays", toList(delay(() => map_2((w) => createElement("div", {
            className: "datepicker-date",
            children: w,
            style: {
                textTransform: "capitalize",
            },
        }), DatePicker_weekRange(p.locale, currentMonth))))), Html_divClassed("datepicker-days", toList(delay(() => collect((d_4) => {
            let elems_5;
            const isCurrentMonth = isSameMonth_1(d_4, currentMonth);
            let isInRange;
            const matchValue_8 = value.From;
            const matchValue_9 = value.To;
            let matchResult_4, e_1, s_5;
            if (matchValue_8.Date != null) {
                if (matchValue_9.Date != null) {
                    matchResult_4 = 0;
                    e_1 = matchValue_9.Date;
                    s_5 = matchValue_8.Date;
                }
                else {
                    matchResult_4 = 1;
                }
            }
            else {
                matchResult_4 = 1;
            }
            switch (matchResult_4) {
                case 0: {
                    isInRange = isWithinInterval(d_4, {
                        end: e_1,
                        start: s_5,
                    });
                    break;
                }
                default:
                    isInRange = false;
            }
            const isStartSelected = defaultArg(map((x_3) => isSameDay(x_3, d_4), DatePicker_DateTimeValueModule_date(value.From)), false);
            const isEndSelected = defaultArg(map((x_4) => isSameDay(x_4, d_4), DatePicker_DateTimeValueModule_date(value.To)), false);
            const isDisabled = (compare(d_4, defaultArg(map(startOfDay, p.minDate), startOfDay(minValue()))) < 0) ? true : (compare(d_4, defaultArg(map(startOfDay, p.maxDate), startOfDay(maxValue()))) > 0);
            return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("datepicker-date"), delay(() => append_1(isCurrentMonth ? singleton_1("is-current-month") : empty(), delay(() => append_1(p.isRange ? append_1(isStartSelected ? singleton_1("datepicker-range-start") : empty(), delay(() => append_1(isEndSelected ? singleton_1("datepicker-range-end") : empty(), delay(() => (isInRange ? singleton_1("datepicker-range") : empty()))))) : empty(), delay(() => (isDisabled ? singleton_1("is-disabled") : empty()))))))))))], (elems_5 = [createElement("button", {
                className: join(" ", toList(delay(() => append_1(singleton_1("date-item"), delay(() => append_1(isToday(d_4) ? singleton_1("is-today") : empty(), delay(() => (isStartSelected ? singleton_1("is-active") : empty())))))))),
                children: day_1(d_4),
                type: "button",
                onClick: (_arg_6) => {
                    onDateSelected(d_4);
                },
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
        }, DatePicker_monthRange(p.locale, currentMonth)))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("datepicker-months"), delay(() => (DatePicker_EditMode__IsMonthMode(editMode) ? singleton_1("is-active") : empty()))))))], (elems_7 = toList(delay(() => collect((i_4) => {
            let locale_2, formatOptions_2;
            const month = create(year_1(currentMonth), i_4, day_1(currentMonth));
            const isSameMonth = i_4 === month_1(currentMonth);
            return singleton_1(createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1("datepicker-month"), delay(() => (isSameMonth ? singleton_1("is-active") : empty())))))),
                children: (locale_2 = p.locale, (formatOptions_2 = {}, (formatOptions_2.locale = locale_2, format_5(month, "LLL", formatOptions_2)))),
                onClick: (_arg_7) => {
                    onCurrentMonthSelected(month);
                },
            }));
        }, toList(rangeDouble(1, 1, 12))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_1("datepicker-years"), delay(() => (DatePicker_EditMode__IsYearMode(editMode) ? singleton_1("is-active") : empty()))))))], (elems_8 = toList(delay(() => collect((i_5) => {
            const year = create(year_1(currentMonth) + i_5, month_1(currentMonth), 1);
            const isSameYear = isSameYear_1(year, currentMonth);
            return singleton_1(createElement("div", {
                className: join(" ", toList(delay(() => append_1(singleton_1("datepicker-year"), delay(() => (isSameYear ? singleton_1("is-active") : empty())))))),
                children: year_1(year),
                onClick: (_arg_8) => {
                    onCurrentMonthSelected(year);
                },
            }));
        }, toList(rangeDouble(-50, 1, 50))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))]))]))), delay(() => (!p.dateOnly ? singleton_1(Html_divClassed("timepicker", toList(delay(() => append_1(singleton_1(Html_divClassed("timepicker-start", ofArray([TimePicker_timePickerHours(sh, (i) => {
            let tupledArg_4;
            onTimeSelected((tupledArg_4 = [i, sm, eh, em], TimePicker_toTimeSpans(tupledArg_4[0], tupledArg_4[1], tupledArg_4[2], tupledArg_4[3])));
        }), Html_divClassed("timepicker-time-divider", singleton(":")), TimePicker_timePickerMinutes(sm, (i_1) => {
            let tupledArg_5;
            onTimeSelected((tupledArg_5 = [sh, i_1, eh, em], TimePicker_toTimeSpans(tupledArg_5[0], tupledArg_5[1], tupledArg_5[2], tupledArg_5[3])));
        })]))), delay(() => (p.isRange ? singleton_1(Html_divClassed("timepicker-end", ofArray([TimePicker_timePickerHours(eh, (i_2) => {
            let tupledArg_6;
            onTimeSelected((tupledArg_6 = [sh, sm, i_2, em], TimePicker_toTimeSpans(tupledArg_6[0], tupledArg_6[1], tupledArg_6[2], tupledArg_6[3])));
        }), Html_divClassed("timepicker-time-divider", singleton(":")), TimePicker_timePickerMinutes(em, (i_3) => {
            let tupledArg_7;
            onTimeSelected((tupledArg_7 = [sh, sm, eh, i_3], TimePicker_toTimeSpans(tupledArg_7[0], tupledArg_7[1], tupledArg_7[2], tupledArg_7[3])));
        })]))) : empty()))))))) : empty())));
    }))))), Html_divClassed("datetimepicker-footer", singleton(createElement("button", {
        className: join(" ", ["datetimepicker-footer-clear", "has-text-danger", "button", "is-small", "is-text"]),
        type: "button",
        children: p.clearLabel,
        onClick: (_arg_1) => {
            updateDate(DatePicker_SelectedValueModule_empty);
        },
    })))]);
    const format_4 = (d_5) => {
        let formatString;
        const matchValue_11 = p.dateFormat;
        const matchValue_12 = p.dateOnly;
        formatString = ((matchValue_11 == null) ? (matchValue_12 ? "dd. MMMM yyyy" : "dd. MMMM yyyy") : matchValue_11);
        const locale_3 = p.locale;
        const formatOptions_3 = {};
        formatOptions_3.locale = locale_3;
        return format_5(d_5, formatString, formatOptions_3);
    };
    const canUserWrite = (p.allowTextInput && !p.isRange) && p.dateOnly;
    let txtValue;
    if (valueFromInput == null) {
        if (p.isRange) {
            const matchValue_14 = DatePicker_DateTimeValueModule_tryToDateTime(p.dateOnly, value.From);
            const matchValue_15 = DatePicker_DateTimeValueModule_tryToDateTime(p.dateOnly, value.To);
            let matchResult_5, e_2, s_6;
            if (matchValue_14 != null) {
                if (matchValue_15 != null) {
                    matchResult_5 = 0;
                    e_2 = matchValue_15;
                    s_6 = matchValue_14;
                }
                else {
                    matchResult_5 = 1;
                }
            }
            else {
                matchResult_5 = 1;
            }
            switch (matchResult_5) {
                case 0: {
                    const arg = format_4(s_6);
                    const arg_1 = format_4(e_2);
                    txtValue = toText(printf("%s - %s"))(arg)(arg_1);
                    break;
                }
                default:
                    txtValue = "";
            }
        }
        else {
            txtValue = defaultArg(map(format_4, DatePicker_DateTimeValueModule_tryToDateTime(p.dateOnly, value.From)), "");
        }
    }
    else {
        txtValue = valueFromInput;
    }
    const children = ofArray([(matchValue_19 = p.displayMode, (matchValue_19.tag === 1) ? defaultOf() : createElement("div", createObj(Helpers_combineClasses("control", ofArray([["className", "has-icons-left"], (elems_10 = [createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", toList(delay(() => append_1(singleton_1(["readOnly", !canUserWrite]), delay(() => {
        let value_79;
        return append_1(singleton_1((value_79 = txtValue, ["ref", (e_3) => {
            if (!(e_3 == null) && !equals(e_3.value, value_79)) {
                e_3.value = value_79;
            }
        }])), delay(() => append_1(singleton_1(["onClick", (_arg_9) => {
            setIsDisplayed(true);
        }]), delay(() => (canUserWrite ? append_1(singleton_1(["onChange", (ev) => {
            const x_8 = ev.target.value;
            const inputValue = x_8;
            try {
                let parsedDate;
                const matchValue_17 = p.dateFormat;
                parsedDate = ((matchValue_17 == null) ? parse(inputValue, "dd. MMMM yyyy", 0) : parse(inputValue, matchValue_17, 0));
                if (isValid(parsedDate) && (year_1(parsedDate) > 1000)) {
                    setValue(new DatePicker_SelectedValue(new DatePicker_DateTimeValue(parsedDate, void 0), new DatePicker_DateTimeValue(void 0, void 0)));
                    if (p.onTextChanged != null) {
                        value_96(p.onTextChanged)(new DatePicker_TextInputEventArgs(inputValue, true, parsedDate));
                    }
                    setCurrentMonth(parsedDate);
                    onDateSelected(parsedDate);
                }
                else {
                    if (p.onTextChanged != null) {
                        value_96(p.onTextChanged)(new DatePicker_TextInputEventArgs(inputValue, false, void 0));
                    }
                    setValue(new DatePicker_SelectedValue(new DatePicker_DateTimeValue(void 0, void 0), new DatePicker_DateTimeValue(void 0, void 0)));
                }
            }
            catch (exn) {
            }
            setValueFromInput(x_8);
        }]), delay(() => singleton_1(["onBlur", (_arg_10) => {
            const inputValue_1 = txtValue;
            try {
                let parsedDate_1;
                const matchValue_18 = p.dateFormat;
                parsedDate_1 = ((matchValue_18 == null) ? parse(inputValue_1, "dd. MMMM yyyy", 0) : parse(inputValue_1, matchValue_18, 0));
                if (isValid(parsedDate_1) && (year_1(parsedDate_1) > 1000)) {
                    if (p.onTextInputBlur != null) {
                        value_96(p.onTextInputBlur)(new DatePicker_TextInputEventArgs(inputValue_1, true, parsedDate_1));
                    }
                }
                else {
                    if (p.onTextInputBlur != null) {
                        value_96(p.onTextInputBlur)(new DatePicker_TextInputEventArgs(inputValue_1, false, void 0));
                    }
                }
            }
            catch (exn_1) {
            }
        }]))) : empty())))));
    })))))))), createElement("span", createObj(Helpers_combineClasses("icon", ofArray([["className", "is-left"], ["style", {
        zIndex: 0,
    }], (elems_9 = [createElement("i", createObj(singleton(op_PlusPlus(["className", "far fa-calendar-alt"], ["className", "has-text-grey"]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))))), Layout_wrapper(patternInput_5[0], p.displayMode, myRef, body)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

