import { Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { class_type, array_type, record_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { useGetQuery, useGetQueryWithRefresh } from "./Hooks.fs.js";
import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { createObj, int32ToString, equals, defaultOf } from "./fable_modules/fable-library.4.3.0/Util.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { createElement } from "react";
import React from "react";
import { defaultArg } from "./fable_modules/fable-library.4.3.0/Option.js";
import { Interop_reactApi } from "./fable_modules/Feliz.Recharts.4.2.0/../Feliz.2.7.0/Interop.fs.js";
import { LineChart, Line, CartesianGrid, Tooltip, YAxis, XAxis } from "recharts";
import { toString } from "./fable_modules/fable-library.4.3.0/Date.js";
import { map, equalsWith } from "./fable_modules/fable-library.4.3.0/Array.js";
import { defaultOf as defaultOf_1 } from "./fable_modules/Feliz.2.7.0/../fable-library.4.3.0/Util.js";
import { join } from "./fable_modules/fable-library.4.3.0/String.js";
import { ofArray, singleton as singleton_1 } from "./fable_modules/fable-library.4.3.0/List.js";
import { parse } from "./fable_modules/fable-library.4.3.0/Double.js";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { KPIS_ENDPOINT } from "./Common.fs.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { empty, singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";

export class TwilioBalanceResponse extends Record {
    constructor(Balance, Currency) {
        super();
        this.Balance = Balance;
        this.Currency = Currency;
    }
}

export function TwilioBalanceResponse_$reflection() {
    return record_type("CwwDashboard.Sections.Temperature.TwilioBalanceResponse", [], TwilioBalanceResponse, () => [["Balance", string_type], ["Currency", string_type]]);
}

export class TemperatureDataResponse extends Record {
    constructor(feeds) {
        super();
        this.feeds = feeds;
    }
}

export function TemperatureDataResponse_$reflection() {
    return record_type("CwwDashboard.Sections.Temperature.TemperatureDataResponse", [], TemperatureDataResponse, () => [["feeds", array_type(TemperatureFeed_$reflection())]]);
}

export class TemperatureFeed extends Record {
    constructor(created_at, field1) {
        super();
        this.created_at = created_at;
        this.field1 = field1;
    }
}

export function TemperatureFeed_$reflection() {
    return record_type("CwwDashboard.Sections.Temperature.TemperatureFeed", [], TemperatureFeed, () => [["created_at", class_type("System.DateTime")], ["field1", string_type]]);
}

export function useTemperatureFeed(queryParams) {
    let matchValue;
    const patternInput = useGetQueryWithRefresh(`https://api.thingspeak.com/channels/844936/feeds.json?${queryParams}`, () => singleton.Return(void 0));
    const refresh = patternInput[2];
    if (patternInput[0]) {
        return [true, void 0, defaultOf(), refresh];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(TemperatureDataResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return [false, parseResult.fields[0], defaultOf(), refresh];
        }
        else {
            return [false, void 0, parseResult.fields[0], refresh];
        }
    }
}

export function TemperatureChart() {
    let elements, properties_3;
    const patternInput = useTemperatureFeed("minutes=120");
    if (!patternInput[0] && (patternInput[1] == null)) {
        const properties_5 = ofArray([["width", 500], ["height", 250], ["margin", {
            top: defaultArg(10, 0),
            right: defaultArg(30, 0),
            left: defaultArg(void 0, 0),
            bottom: defaultArg(void 0, 0),
        }], ["data", patternInput[2].feeds], (elements = ofArray([Interop_reactApi.createElement(XAxis, {
            dataKey: (feed) => toString(feed.created_at, "HH:mm"),
            interval: 24,
        }), Interop_reactApi.createElement(YAxis, {}), Interop_reactApi.createElement(Tooltip, {
            content: (render) => {
                const data = render;
                if (data.active && !equalsWith(equals, data.payload, defaultOf())) {
                    return `${data.payload[0].value}°C (${data.label})`;
                }
                else {
                    return defaultOf_1();
                }
            },
        }), (properties_3 = singleton_1(["strokeDasharray", join(" ", map(int32ToString, new Int32Array([3, 3])))]), Interop_reactApi.createElement(CartesianGrid, createObj(properties_3))), Interop_reactApi.createElement(Line, {
            type: "monotone",
            dot: false,
            dataKey: (point) => parse(point.field1),
            stroke: "#ff4e00",
            strokeWidth: 2,
        })]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]);
        return Interop_reactApi.createElement(LineChart, createObj(properties_5));
    }
    else {
        return defaultOf_1();
    }
}

export function TemperatureSection() {
    let matchValue, elems_3, elms;
    let patternInput_1;
    const patternInput = useGetQuery(`${KPIS_ENDPOINT}/twilio/balance`);
    if (patternInput[0]) {
        patternInput_1 = [true, void 0, defaultOf()];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(TwilioBalanceResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        patternInput_1 = ((parseResult.tag === 1) ? [false, parseResult.fields[0], defaultOf()] : [false, void 0, parseResult.fields[0]]);
    }
    const twilioBalance = patternInput_1[2];
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray([["className", "is-danger"], (elems_3 = [createElement("div", {
        className: "message-header",
        children: Interop_reactApi_1.Children.toArray(["Temperature Status"]),
    }), (elms = toList(delay(() => {
        let elems_1;
        return append((!patternInput_1[0] && (patternInput_1[1] == null)) ? singleton_2(createElement("div", createObj(ofArray([["className", "is-size-5 mb-5"], ["style", {
            display: "flex",
            alignItems: "center",
        }], (elems_1 = [createElement("b", {
            children: ["Twilio Balance:"],
        }), createElement("div", {
            className: "ml-5",
            children: `${twilioBalance.Balance} ${twilioBalance.Currency}`,
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))) : empty(), delay(() => singleton_2(createElement(TemperatureChart, null))));
    })), createElement("div", {
        className: "message-body",
        children: Interop_reactApi_1.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])]))));
}

