import { Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { option_type, class_type, decimal_type, int32_type, bool_type, anonRecord_type, array_type, record_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { join } from "./fable_modules/fable-library.4.3.0/String.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { map } from "./fable_modules/fable-library.4.3.0/Array.js";

export class ApiWarning extends Record {
    constructor(title, detail) {
        super();
        this.title = title;
        this.detail = detail;
    }
}

export function ApiWarning_$reflection() {
    return record_type("CwwDashboard.Dto.ApiWarning", [], ApiWarning, () => [["title", string_type], ["detail", string_type]]);
}

export class ApiResponse$1 extends Record {
    constructor(value, warnings) {
        super();
        this.value = value;
        this.warnings = warnings;
    }
}

export function ApiResponse$1_$reflection(gen0) {
    return record_type("CwwDashboard.Dto.ApiResponse`1", [gen0], ApiResponse$1, () => [["value", gen0], ["warnings", array_type(ApiWarning_$reflection())]]);
}

export class WineBottlesResponse extends Record {
    constructor(data) {
        super();
        this.data = data;
    }
}

export function WineBottlesResponse_$reflection() {
    return record_type("CwwDashboard.Dto.WineBottlesResponse", [], WineBottlesResponse, () => [["data", anonRecord_type(["wine_bottles", array_type(WineBottle_$reflection())])]]);
}

export class WineBottle extends Record {
    constructor(id, name, vintage, invoice_name, discontinued) {
        super();
        this.id = id;
        this.name = name;
        this.vintage = vintage;
        this.invoice_name = invoice_name;
        this.discontinued = discontinued;
    }
}

export function WineBottle_$reflection() {
    return record_type("CwwDashboard.Dto.WineBottle", [], WineBottle, () => [["id", string_type], ["name", string_type], ["vintage", string_type], ["invoice_name", string_type], ["discontinued", bool_type]]);
}

export class InvoiceItem extends Record {
    constructor(ProductName, Quantity, UnitPrice) {
        super();
        this.ProductName = ProductName;
        this.Quantity = (Quantity | 0);
        this.UnitPrice = UnitPrice;
    }
}

export function InvoiceItem_$reflection() {
    return record_type("CwwDashboard.Dto.InvoiceItem", [], InvoiceItem, () => [["ProductName", string_type], ["Quantity", int32_type], ["UnitPrice", decimal_type]]);
}

export class Invoice extends Record {
    constructor(InvoiceDate, InvoiceNumber, Memo, TotalPrice, CustomerName, Items, ViewUrl) {
        super();
        this.InvoiceDate = InvoiceDate;
        this.InvoiceNumber = InvoiceNumber;
        this.Memo = Memo;
        this.TotalPrice = TotalPrice;
        this.CustomerName = CustomerName;
        this.Items = Items;
        this.ViewUrl = ViewUrl;
    }
}

export function Invoice_$reflection() {
    return record_type("CwwDashboard.Dto.Invoice", [], Invoice, () => [["InvoiceDate", class_type("System.DateTime")], ["InvoiceNumber", string_type], ["Memo", string_type], ["TotalPrice", decimal_type], ["CustomerName", string_type], ["Items", array_type(InvoiceItem_$reflection())], ["ViewUrl", string_type]]);
}

export function Invoice__FormatDescription(x) {
    return join(" ", toList(delay(() => append((x.CustomerName !== "Anonymous individual") ? singleton(`${x.CustomerName} - `) : singleton("(anonymous) "), delay(() => {
        let array;
        return singleton(join(";", map((x_1) => x_1, (array = x.Memo.split("\n"), array.filter((line) => !((line.indexOf("៛") >= 0) ? true : (line.indexOf("(1 USD = ") >= 0)))))));
    })))));
}

export class Loss extends Record {
    constructor(Date$, Product, Quantity, Comment$) {
        super();
        this.Date = Date$;
        this.Product = Product;
        this.Quantity = (Quantity | 0);
        this.Comment = Comment$;
    }
}

export function Loss_$reflection() {
    return record_type("CwwDashboard.Dto.Loss", [], Loss, () => [["Date", class_type("System.DateTime")], ["Product", string_type], ["Quantity", int32_type], ["Comment", string_type]]);
}

export class GraphQLError extends Record {
    constructor(message) {
        super();
        this.message = message;
    }
}

export function GraphQLError_$reflection() {
    return record_type("CwwDashboard.Dto.GraphQLError", [], GraphQLError, () => [["message", string_type]]);
}

export class GraphQLResponse$1 extends Record {
    constructor(errors, data) {
        super();
        this.errors = errors;
        this.data = data;
    }
}

export function GraphQLResponse$1_$reflection(gen0) {
    return record_type("CwwDashboard.Dto.GraphQLResponse`1", [gen0], GraphQLResponse$1, () => [["errors", option_type(array_type(GraphQLError_$reflection()))], ["data", gen0]]);
}

