import { toString, Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { bool_type, string_type, record_type, option_type, class_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { useGetQueryWithRefresh } from "./Hooks.fs.js";
import { printf, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { KPIS_ENDPOINT } from "./Common.fs.js";
import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { createObj, defaultOf } from "./fable_modules/fable-library.4.3.0/Util.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { map, defaultArg } from "./fable_modules/fable-library.4.3.0/Option.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { singleton as singleton_2, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { collect, empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { equalsWith, map as map_1, take, reverse } from "./fable_modules/fable-library.4.3.0/Array.js";
import { toArray } from "./fable_modules/fable-library.4.3.0/Map.js";
import { parse } from "./fable_modules/fable-library.4.3.0/Int32.js";
import { startImmediate } from "./fable_modules/fable-library.4.3.0/Async.js";
import { invalidateMonthPredeliveries, invalidateStartOfMonthInventory, invalidateMonthWarehouseMovements, reloadMonthInvoices, invalidateSnapshotBasedStartOfMonthInventory, reloadLosses, reloadPurchases, invalidateGlobalInventorySnapshotRepository } from "./FastKpisClient.fs.js";

export class GrainState extends Record {
    constructor(lastTimeCached, lastTimeStateWritten, lastTimeStateRead, lastTimeStateCleared) {
        super();
        this.lastTimeCached = lastTimeCached;
        this.lastTimeStateWritten = lastTimeStateWritten;
        this.lastTimeStateRead = lastTimeStateRead;
        this.lastTimeStateCleared = lastTimeStateCleared;
    }
}

export function GrainState_$reflection() {
    return record_type("CwwDashboard.Monitoring.GrainState", [], GrainState, () => [["lastTimeCached", option_type(class_type("System.DateTimeOffset"))], ["lastTimeStateWritten", option_type(class_type("System.DateTimeOffset"))], ["lastTimeStateRead", option_type(class_type("System.DateTimeOffset"))], ["lastTimeStateCleared", option_type(class_type("System.DateTimeOffset"))]]);
}

export class EnvironmentResponse extends Record {
    constructor(Environment, CmsEndpoint, UseGrainPersistence, IsOffline, StartOfMonthInventoryGrains, SnapshotBasedStartOfMonthInventoryGrains, InvoiceGrains, WarehouseMovementsGrains, PredeliveriesGrains, GlobalInventorySnapshotRepositoryGrain, PurchasesGrain, LossesGrain) {
        super();
        this.Environment = Environment;
        this.CmsEndpoint = CmsEndpoint;
        this.UseGrainPersistence = UseGrainPersistence;
        this.IsOffline = IsOffline;
        this.StartOfMonthInventoryGrains = StartOfMonthInventoryGrains;
        this.SnapshotBasedStartOfMonthInventoryGrains = SnapshotBasedStartOfMonthInventoryGrains;
        this.InvoiceGrains = InvoiceGrains;
        this.WarehouseMovementsGrains = WarehouseMovementsGrains;
        this.PredeliveriesGrains = PredeliveriesGrains;
        this.GlobalInventorySnapshotRepositoryGrain = GlobalInventorySnapshotRepositoryGrain;
        this.PurchasesGrain = PurchasesGrain;
        this.LossesGrain = LossesGrain;
    }
}

export function EnvironmentResponse_$reflection() {
    return record_type("CwwDashboard.Monitoring.EnvironmentResponse", [], EnvironmentResponse, () => [["Environment", string_type], ["CmsEndpoint", string_type], ["UseGrainPersistence", bool_type], ["IsOffline", bool_type], ["StartOfMonthInventoryGrains", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, GrainState_$reflection()])], ["SnapshotBasedStartOfMonthInventoryGrains", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, GrainState_$reflection()])], ["InvoiceGrains", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, GrainState_$reflection()])], ["WarehouseMovementsGrains", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, GrainState_$reflection()])], ["PredeliveriesGrains", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, GrainState_$reflection()])], ["GlobalInventorySnapshotRepositoryGrain", GrainState_$reflection()], ["PurchasesGrain", GrainState_$reflection()], ["LossesGrain", GrainState_$reflection()]]);
}

export function useGrainStatus() {
    let matchValue;
    const patternInput = useGetQueryWithRefresh(toText(printf("%s/environment"))(KPIS_ENDPOINT), () => singleton.Delay(() => singleton.Return(void 0)));
    const refresh = patternInput[2];
    if (patternInput[0]) {
        return [true, void 0, defaultOf(), refresh];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(EnvironmentResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return [false, parseResult.fields[0], defaultOf(), refresh];
        }
        else {
            return [false, void 0, parseResult.fields[0], refresh];
        }
    }
}

export class GrainStatusDisplayOptions extends Record {
    constructor(WithPersistenceState, ActionButtonText) {
        super();
        this.WithPersistenceState = WithPersistenceState;
        this.ActionButtonText = ActionButtonText;
    }
}

export function GrainStatusDisplayOptions_$reflection() {
    return record_type("CwwDashboard.Monitoring.GrainStatusDisplayOptions", [], GrainStatusDisplayOptions, () => [["WithPersistenceState", bool_type], ["ActionButtonText", string_type]]);
}

export function GrainStatusDisplayOptions_get_Default() {
    return new GrainStatusDisplayOptions(false, "Reload");
}

export function GrainsStatuses(grainsStatusesInputProps) {
    let elems_3, elems, elms, elems_1, children_2, children, children_22;
    const invalidateGrain = grainsStatusesInputProps.invalidateGrain;
    const statusMap = grainsStatusesInputProps.statusMap;
    const options = grainsStatusesInputProps.options;
    const title = grainsStatusesInputProps.title;
    const className = grainsStatusesInputProps.className;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const expandAll = patternInput[0];
    const displayEventTime = (eventTime) => defaultArg(map((eventTime_1) => toText(printf("%A"))(eventTime_1), eventTime), "-");
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray([["className", className], (elems_3 = [createElement("div", createObj(Helpers_combineClasses("message-header", ofArray([["className", "is-flex is-align-items-baseline"], (elems = [title, createElement("div", {
        className: "is-flex is-justify-content-end",
        children: createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-outlined"], ["className", "is-ghost"], ["onClick", (_arg) => {
            patternInput[1](!expandAll);
        }], ["children", expandAll ? "Collapse" : "Expand"]])))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), (elms = singleton_2(createElement("table", createObj(ofArray([["className", "table is-narrow is-hoverable is-striped is-fullwidth"], (elems_1 = [(children_2 = singleton_2((children = toList(delay(() => append(singleton_1(createElement("th", {
        children: ["Grain ID"],
    })), delay(() => append(singleton_1(createElement("th", {
        children: ["Last Cached"],
    })), delay(() => append(options.WithPersistenceState ? append(singleton_1(createElement("th", {
        children: ["Last State Written"],
    })), delay(() => append(singleton_1(createElement("th", {
        children: ["Last State Read"],
    })), delay(() => singleton_1(createElement("th", {
        children: ["Last State Cleared"],
    })))))) : empty(), delay(() => singleton_1(createElement("th", {
        children: ["Actions"],
    })))))))))), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_22 = toList(delay(() => {
        const statusMap_1 = reverse(toArray(statusMap));
        return append(collect((matchValue) => {
            let children_6;
            const grainStatus = matchValue[1];
            const grainKey = matchValue[0];
            return singleton_1((children_6 = toList(delay(() => append(singleton_1(createElement("td", {
                children: [grainKey],
            })), delay(() => append(singleton_1(createElement("td", {
                children: [displayEventTime(grainStatus.lastTimeCached)],
            })), delay(() => append(options.WithPersistenceState ? append(singleton_1(createElement("td", {
                children: [displayEventTime(grainStatus.lastTimeStateWritten)],
            })), delay(() => append(singleton_1(createElement("td", {
                children: [displayEventTime(grainStatus.lastTimeStateRead)],
            })), delay(() => singleton_1(createElement("td", {
                children: [displayEventTime(grainStatus.lastTimeStateCleared)],
            })))))) : empty(), delay(() => {
                let children_4;
                return singleton_1((children_4 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-info"], ["className", "is-light"], ["className", "is-small"], ["onClick", (_arg_1) => {
                    invalidateGrain(grainKey);
                }], ["children", options.ActionButtonText]]))))), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                })));
            })))))))), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })));
        }, expandAll ? statusMap_1 : take(12, statusMap_1)), delay(() => {
            let children_20;
            return (!expandAll && (statusMap_1.length > 12)) ? singleton_1((children_20 = toList(delay(() => append(singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })), delay(() => append(singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })), delay(() => append(options.WithPersistenceState ? append(singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })), delay(() => append(singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })), delay(() => singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })))))) : empty(), delay(() => singleton_1(createElement("td", {
                children: Interop_reactApi.Children.toArray(["..."]),
            })))))))))), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_20)),
            }))) : empty();
        }));
    })), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_22)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", {
        className: "message-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function MonitoringPage() {
    let elems_10, elems_4, elems_3, elms, elems, children_2, children_12, children_4, children_6, children_8, children_10, elems_1, children_16, children_14, children_30, children_20, children_18, children_24, children_22, children_28, children_26, elems_9, elems_6, elems_5, elems_8, elems_7, inputRecord_2;
    const patternInput = useGrainStatus();
    const refresh = patternInput[3];
    const data = patternInput[2];
    const grainKeyAsYearMonth = (grainKey) => {
        const _arg = map_1((value) => parse(value, 511, false, 32), grainKey.split("-"), Int32Array);
        if (!equalsWith((x, y) => (x === y), _arg, defaultOf()) && (_arg.length === 2)) {
            return [_arg[0], _arg[1]];
        }
        else {
            throw new Error("Invalid grain key");
        }
    };
    const showConfigValue = (value_1) => {
        const children = singleton_2(createElement("span", {
            className: "tag",
            children: value_1,
        }));
        return createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    };
    const displayEventTime = (eventTime) => defaultArg(map((eventTime_1) => toText(printf("%A"))(eventTime_1), eventTime), "-");
    if (patternInput[0]) {
        return "...";
    }
    else if (patternInput[1] != null) {
        return "error";
    }
    else {
        return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], ["className", "is-vertical"], (elems_10 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], ["children", createElement("article", createObj(Helpers_combineClasses("message", singleton_2((elems_3 = [createElement("div", createObj(Helpers_combineClasses("message-header", ofArray([["className", "is-flex is-align-items-baseline"], ["children", "General Status"]])))), (elms = ofArray([createElement("table", createObj(ofArray([["className", "table is-narrow is-hoverable is-striped"], (elems = [(children_2 = singleton_2(createElement("tr", {})), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_12 = ofArray([(children_4 = ofArray([createElement("th", {
            children: ["Environment"],
        }), showConfigValue(data.Environment)]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = ofArray([createElement("th", {
            children: ["CMS Endpoint"],
        }), showConfigValue(data.CmsEndpoint)]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })), (children_8 = ofArray([createElement("th", {
            children: ["Use Grain Persistence"],
        }), showConfigValue(toString(data.UseGrainPersistence))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        })), (children_10 = ofArray([createElement("th", {
            children: ["Is Offline"],
        }), showConfigValue(toString(data.IsOffline))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        }))]), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("table", createObj(ofArray([["className", "table is-narrow is-hoverable is-striped"], (elems_1 = [(children_16 = singleton_2((children_14 = ofArray([createElement("th", {
            children: ["Grain"],
        }), createElement("th", {
            children: ["Last Cached"],
        }), createElement("th", {
            children: ["Actions"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_16)),
        })), (children_30 = ofArray([(children_20 = ofArray([createElement("th", {
            children: ["GlobalInventorySnapshotRepositoryGrain"],
        }), createElement("td", {
            children: [displayEventTime(data.GlobalInventorySnapshotRepositoryGrain.lastTimeCached)],
        }), (children_18 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-info"], ["className", "is-light"], ["className", "is-small"], ["onClick", (_arg_1) => {
            startImmediate(singleton.Delay(() => singleton.Bind(invalidateGlobalInventorySnapshotRepository(), () => {
                refresh();
                return singleton.Zero();
            })));
        }], ["children", "Invalidate"]]))))), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children_18)),
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_20)),
        })), (children_24 = ofArray([createElement("th", {
            children: ["PurchasesGrain"],
        }), createElement("td", {
            children: [displayEventTime(data.PurchasesGrain.lastTimeCached)],
        }), (children_22 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-info"], ["className", "is-light"], ["className", "is-small"], ["onClick", (_arg_3) => {
            startImmediate(singleton.Delay(() => singleton.Bind(reloadPurchases(), () => {
                refresh();
                return singleton.Zero();
            })));
        }], ["children", "Reload"]]))))), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children_22)),
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_24)),
        })), (children_28 = ofArray([createElement("th", {
            children: ["LossesGrain"],
        }), createElement("td", {
            children: [displayEventTime(data.LossesGrain.lastTimeCached)],
        }), (children_26 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-info"], ["className", "is-light"], ["className", "is-small"], ["onClick", (_arg_5) => {
            startImmediate(singleton.Delay(() => singleton.Bind(reloadLosses(), () => {
                refresh();
                return singleton.Zero();
            })));
        }], ["children", "Reload"]]))))), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children_26)),
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_28)),
        }))]), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_30)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", {
            className: "message-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])))))]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("div", createObj(Helpers_combineClasses("tile", singleton_2((elems_9 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], (elems_6 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems_5 = [createElement(GrainsStatuses, {
            className: "is-warning",
            title: "Snapshot-based Start of Month Inventory Grains",
            options: new GrainStatusDisplayOptions(GrainStatusDisplayOptions_get_Default().WithPersistenceState, "Invalidate"),
            statusMap: data.SnapshotBasedStartOfMonthInventoryGrains,
            invalidateGrain: (grainKey_1) => {
                startImmediate(singleton.Delay(() => {
                    const patternInput_1 = grainKeyAsYearMonth(grainKey_1);
                    return singleton.Bind(invalidateSnapshotBasedStartOfMonthInventory(patternInput_1[0], patternInput_1[1]), () => {
                        refresh();
                        return singleton.Zero();
                    });
                }));
            },
        }), createElement(GrainsStatuses, {
            className: "is-primary",
            title: "Invoices Grain",
            options: GrainStatusDisplayOptions_get_Default(),
            statusMap: data.InvoiceGrains,
            invalidateGrain: (grainKey_2) => {
                startImmediate(singleton.Delay(() => {
                    const patternInput_2 = grainKeyAsYearMonth(grainKey_2);
                    return singleton.Bind(reloadMonthInvoices(patternInput_2[0], patternInput_2[1]), () => {
                        refresh();
                        return singleton.Zero();
                    });
                }));
            },
        }), createElement(GrainsStatuses, {
            className: "is-success",
            title: "Warehouse Movements Grains",
            options: new GrainStatusDisplayOptions(GrainStatusDisplayOptions_get_Default().WithPersistenceState, "Invalidate"),
            statusMap: data.WarehouseMovementsGrains,
            invalidateGrain: (grainKey_3) => {
                startImmediate(singleton.Delay(() => {
                    const patternInput_3 = grainKeyAsYearMonth(grainKey_3);
                    return singleton.Bind(invalidateMonthWarehouseMovements(patternInput_3[0], patternInput_3[1]), () => {
                        refresh();
                        return singleton.Zero();
                    });
                }));
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_8 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems_7 = [createElement(GrainsStatuses, {
            className: "is-info",
            title: "Start of Month Inventory Grains",
            options: (inputRecord_2 = GrainStatusDisplayOptions_get_Default(), new GrainStatusDisplayOptions(true, "Invalidate")),
            statusMap: data.StartOfMonthInventoryGrains,
            invalidateGrain: (grainKey_4) => {
                startImmediate(singleton.Delay(() => {
                    const patternInput_4 = grainKeyAsYearMonth(grainKey_4);
                    return singleton.Bind(invalidateStartOfMonthInventory(patternInput_4[0], patternInput_4[1]), () => {
                        refresh();
                        return singleton.Zero();
                    });
                }));
            },
        }), createElement(GrainsStatuses, {
            className: "is-danger",
            title: "Predeliveries Grains",
            options: new GrainStatusDisplayOptions(GrainStatusDisplayOptions_get_Default().WithPersistenceState, "Invalidate"),
            statusMap: data.PredeliveriesGrains,
            invalidateGrain: (grainKey_5) => {
                startImmediate(singleton.Delay(() => {
                    const patternInput_5 = grainKeyAsYearMonth(grainKey_5);
                    return singleton.Bind(invalidateMonthPredeliveries(patternInput_5[0], patternInput_5[1]), () => {
                        refresh();
                        return singleton.Zero();
                    });
                }));
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))));
    }
}

