import { Union, Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { InvoiceItem_$reflection, Invoice_$reflection } from "./Dto.fs.js";
import { union_type, array_type, string_type, anonRecord_type, int32_type, class_type, record_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { createElement } from "react";
import React from "react";
import { useGetQuery } from "./Hooks.fs.js";
import { LoadingElement, KPIS_ENDPOINT } from "./Common.fs.js";
import { uncurry2, createObj, defaultOf } from "./fable_modules/fable-library.4.3.0/Util.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { format as format_3 } from "date-fns";
import { cons, singleton, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { empty, toArray, singleton as singleton_1, append, map, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { join, printf, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { useWineBottles } from "./DataSources.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { sortWith } from "./fable_modules/fable-library.4.3.0/Array.js";

export class BottleSale extends Record {
    constructor(invoice, item) {
        super();
        this.invoice = invoice;
        this.item = item;
    }
}

export function BottleSale_$reflection() {
    return record_type("CwwDashboard.Sections.BottleInspector.BottleSale", [], BottleSale, () => [["invoice", Invoice_$reflection()], ["item", InvoiceItem_$reflection()]]);
}

export class BottleStoryResponse extends Record {
    constructor(startStock, endStock, messages, events) {
        super();
        this.startStock = startStock;
        this.endStock = endStock;
        this.messages = messages;
        this.events = events;
    }
}

export function BottleStoryResponse_$reflection() {
    return record_type("CwwDashboard.Sections.BottleInspector.BottleStoryResponse", [], BottleStoryResponse, () => [["startStock", anonRecord_type(["date", class_type("System.DateTime")], ["value", int32_type])], ["endStock", anonRecord_type(["date", class_type("System.DateTime")], ["value", int32_type])], ["messages", array_type(string_type)], ["events", array_type(anonRecord_type(["date", class_type("System.DateTime")], ["event", BottleStoryEventPayload_$reflection()]))]]);
}

export class BottleStoryEventPayload extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Purchase", "Reimburse", "Sell", "Lose", "Move"];
    }
}

export function BottleStoryEventPayload_$reflection() {
    return union_type("CwwDashboard.Sections.BottleInspector.BottleStoryEventPayload", [], BottleStoryEventPayload, () => [[["count", int32_type]], [["count", int32_type], ["invoiceNumber", string_type], ["at", string_type]], [["count", int32_type], ["invoiceNumber", string_type], ["invoiceUrl", string_type], ["at", string_type], ["description", string_type]], [["count", int32_type]], [["count", int32_type], ["from", string_type], ["to", string_type]]]);
}

export function BottleStoryTable(bottleStoryTableInputProps) {
    let matchValue, elems_1, children, elems, children_4, children_2, children_10, children_12;
    const bottleName = bottleStoryTableInputProps.bottleName;
    let patternInput_1;
    const patternInput = useGetQuery(`${KPIS_ENDPOINT}/bottle/story/all/${encodeURIComponent(bottleName)}`);
    if (patternInput[0]) {
        patternInput_1 = [true, void 0, defaultOf()];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(BottleStoryResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        patternInput_1 = ((parseResult.tag === 1) ? [false, parseResult.fields[0], defaultOf()] : [false, void 0, parseResult.fields[0]]);
    }
    const error = patternInput_1[1];
    const bottleStoryResponse = patternInput_1[2];
    if (patternInput_1[0]) {
        return createElement(LoadingElement, null);
    }
    else if (error != null) {
        return createElement("span", {
            className: "tag",
            children: `Error getting bottle sales: ${error}`,
        });
    }
    else {
        return createElement("div", createObj(ofArray([["style", {
            maxHeight: 80 + "vh",
            overflowY: "auto",
        }], (elems_1 = [(children = ofArray([createElement("strong", {
            children: ["Start Stock = "],
        }), `${bottleStoryResponse.startStock.value} (as ${format_3(bottleStoryResponse.startStock.date, "dd MMM yyyy")})`]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "is-narrow is-hoverable is-striped"], (elems = [(children_4 = singleton((children_2 = ofArray([createElement("th", {
            children: ["Date"],
        }), createElement("th", {
            children: ["Event Payload"],
        }), createElement("th", {
            children: ["Stock"],
        }), createElement("th", {
            children: ["Details"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_10 = toList(delay(() => {
            let currentCount = 0;
            return map((evt) => {
                const children_8 = toList(delay(() => {
                    let matchValue_2;
                    currentCount = (((matchValue_2 = evt.event, (matchValue_2.tag === 1) ? (currentCount + matchValue_2.fields[0]) : ((matchValue_2.tag === 2) ? (currentCount - matchValue_2.fields[0]) : ((matchValue_2.tag === 3) ? (currentCount - matchValue_2.fields[0]) : ((matchValue_2.tag === 4) ? currentCount : (currentCount + matchValue_2.fields[0])))))) | 0);
                    return append(singleton_1(createElement("td", {
                        children: [format_3(evt.date, "yyyy/MM/dd")],
                    })), delay(() => {
                        let matchValue_3;
                        return append((matchValue_3 = evt.event, (matchValue_3.tag === 1) ? singleton_1(createElement("td", {
                            children: [`Reimburse ${matchValue_3.fields[0]}`],
                        })) : ((matchValue_3.tag === 2) ? singleton_1(createElement("td", {
                            children: [`Sell ${matchValue_3.fields[0]}`],
                        })) : ((matchValue_3.tag === 3) ? singleton_1(createElement("td", {
                            children: [`Lose ${matchValue_3.fields[0]}`],
                        })) : ((matchValue_3.tag === 4) ? singleton_1(createElement("td", {
                            children: [`Move ${matchValue_3.fields[0]} from [${matchValue_3.fields[1]}] to [${matchValue_3.fields[2]}]`],
                        })) : singleton_1(createElement("td", {
                            children: [`Purchase ${matchValue_3.fields[0]}`],
                        })))))), delay(() => append(singleton_1(createElement("td", {
                            className: "has-text-right",
                            children: currentCount,
                        })), delay(() => {
                            let children_6;
                            const matchValue_4 = evt.event;
                            return (matchValue_4.tag === 1) ? singleton_1(createElement("td", {
                                children: [`Credit Note #${matchValue_4.fields[1]} [${matchValue_4.fields[2]}]`],
                            })) : ((matchValue_4.tag === 2) ? singleton_1((children_6 = ofArray(["Inv ", createElement("a", {
                                href: matchValue_4.fields[2],
                                target: "_blank",
                                children: toText(printf("#%s"))(matchValue_4.fields[1]),
                            }), ` [${matchValue_4.fields[3]}] ${matchValue_4.fields[4]}`]), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                            }))) : singleton_1(createElement("td", {
                                children: [""],
                            })));
                        }))));
                    }));
                }));
                return createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                });
            }, bottleStoryResponse.events);
        })), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), (children_12 = ofArray([createElement("strong", {
            children: ["End Stock = "],
        }), `${bottleStoryResponse.endStock.value} (as ${format_3(bottleStoryResponse.endStock.date, "dd MMM yyyy")})`]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    }
}

export function BottleStorySection() {
    let elems_5, elems_4;
    const result = useWineBottles();
    const patternInput = useFeliz_React__React_useState_Static_1505(defaultOf());
    const bottleName = patternInput[0];
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray([["className", "is-link"], (elems_5 = [createElement("div", {
        className: "message-header",
        children: Interop_reactApi.Children.toArray(["Bottle story"]),
    }), createElement("div", createObj(Helpers_combineClasses("message-body", singleton((elems_4 = toList(delay(() => {
        let elms_1, patternInput_1, elems_1, elems_2;
        switch (result.tag) {
            case 1: {
                let bottles;
                const array = toArray(delay(() => map((bottle) => bottle.invoice_name, result.fields[0].data.wine_bottles)));
                bottles = sortWith(uncurry2(x => y => (new Intl.Collator("fr")).compare(x, y)), array);
                return append(singleton_1((elms_1 = ofArray([createElement("label", {
                    className: "label",
                    children: "Bottle",
                }), (patternInput_1 = Helpers_extractClasses(ofArray([["onChange", (ev) => {
                    const x = ev.target.value;
                    patternInput[1]((x === "") ? defaultOf() : x);
                }], (elems_1 = toList(delay(() => append(singleton_1(createElement("option", {
                    value: "",
                    children: "Select bottle:",
                })), delay(() => map((bottle_1) => createElement("option", {
                    key: bottle_1,
                    value: bottle_1,
                    children: bottle_1,
                }), bottles))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])), createElement("div", createObj(ofArray([["className", join(" ", cons("select", patternInput_1[0]))], (elems_2 = [createElement("select", createObj(patternInput_1[1]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => ((bottleName !== defaultOf()) ? singleton_1(createElement(BottleStoryTable, {
                    bottleName: bottleName,
                })) : empty())));
            }
            case 2:
                return singleton_1(createElement("span", {
                    className: "tag",
                    children: `Error fetching wine bottles: ${result.fields[0]}`,
                }));
            default:
                return singleton_1(createElement(LoadingElement, null));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))));
}

