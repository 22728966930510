import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { Convert_serialize } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { union_type, anonRecord_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { HttpMethod, BodyContent } from "./fable_modules/Fable.SimpleHttp.3.0.0/Types.fs.js";
import { Http_get, Headers_contentType, Http_header, Http_request, Http_content, Http_method, Http_send } from "./fable_modules/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { CMS_ENDPOINT } from "./Common.fs.js";
import { useReact_useEffect_311B4086, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { startImmediate } from "./fable_modules/fable-library.4.3.0/Async.js";
import { Union } from "./fable_modules/fable-library.4.3.0/Types.js";

export function gqlQueryImpl(endpoint, query) {
    return singleton.Delay(() => {
        let req_2;
        const data = new BodyContent(1, [Convert_serialize({
            query: query,
        }, createTypeInfo(anonRecord_type(["query", string_type])))]);
        return singleton.Bind(Http_send((req_2 = Http_method(new HttpMethod(1, []), Http_content(data, Http_request(endpoint))), Http_header(Headers_contentType("application/json"), req_2))), (_arg) => {
            const response = _arg;
            return singleton.Return([response.statusCode, response.responseText]);
        });
    });
}

export function gqlQuery(query) {
    return gqlQueryImpl(`${CMS_ENDPOINT}/graphql`, query);
}

export function systemGqlQuery(query) {
    return gqlQueryImpl(`${CMS_ENDPOINT}/graphql/system`, query);
}

export function useGql(query) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    useReact_useEffect_311B4086(() => {
        startImmediate(singleton.Delay(() => {
            setLoading(true);
            return singleton.Bind(gqlQuery(query), (_arg) => {
                setLoading(false);
                patternInput_1[1](_arg[1]);
                return singleton.Zero();
            });
        }));
    }, []);
    return [patternInput[0], patternInput_1[0]];
}

export class QueryResponse$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["IsLoading", "Loaded", "IsErrored"];
    }
}

export function QueryResponse$1_$reflection(gen0) {
    return union_type("CwwDashboard.Hooks.QueryResponse`1", [gen0], QueryResponse$1, () => [[], [["Item", gen0]], [["Item", string_type]]]);
}

export class GqlQueryResponse$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Unloaded", "IsLoading", "Loaded", "IsErrored"];
    }
}

export function GqlQueryResponse$1_$reflection(gen0) {
    return union_type("CwwDashboard.Hooks.GqlQueryResponse`1", [gen0], GqlQueryResponse$1, () => [[], [], [["Item", gen0]], [["Item", string_type]]]);
}

export function useGetQuery(url) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    useReact_useEffect_311B4086(() => {
        startImmediate(singleton.Delay(() => {
            setLoading(true);
            return singleton.Bind(Http_get(url), (_arg) => {
                setLoading(false);
                patternInput_1[1](_arg[1]);
                return singleton.Zero();
            });
        }));
    }, [url]);
    return [patternInput[0], patternInput_1[0]];
}

export function useGetQueryWithRefresh(url, beforeRefresh) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const setContent = patternInput_1[1];
    useReact_useEffect_311B4086(() => {
        startImmediate(singleton.Delay(() => {
            setLoading(true);
            return singleton.Bind(Http_get(url), (_arg) => {
                setLoading(false);
                setContent(_arg[1]);
                return singleton.Zero();
            });
        }));
    }, [url]);
    return [patternInput[0], patternInput_1[0], () => {
        startImmediate(singleton.Delay(() => {
            setLoading(true);
            return singleton.Bind(beforeRefresh(), () => singleton.Bind(Http_get(url), (_arg_2) => {
                setLoading(false);
                setContent(_arg_2[1]);
                return singleton.Zero();
            }));
        }));
    }];
}

