import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { ofArray, singleton } from "./fable_modules/fable-library.4.3.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";

export function WarningMessage(warningMessageInputProps) {
    let elems;
    const children = warningMessageInputProps.children;
    const title = warningMessageInputProps.title;
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray([["className", "is-warning"], ["className", "is-size-7"], (elems = [createElement("div", createObj(Helpers_combineClasses("message-header", singleton(["children", title])))), createElement("div", createObj(Helpers_combineClasses("message-body", ofArray([["className", "px-3"], ["className", "py-2"], ["className", "content"], ["children", children]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
}

export function ApiWarningMessage(apiWarning) {
    return createElement(WarningMessage, {
        title: apiWarning.title,
        children: apiWarning.detail,
    });
}

