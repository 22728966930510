import { createElement } from "react";
import React from "react";
import * as react from "react";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { tail, empty as empty_1, head, singleton as singleton_1, isEmpty, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { createObj } from "./fable_modules/fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { InventorySection } from "./InventorySection.fs.js";
import { BottleStorySection } from "./BottleStorySection.fs.js";
import { SalesSection } from "./SalesSection.fs.js";
import { TemperatureSection } from "./TemperatureSection.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { MonitoringPage } from "./Monitoring.fs.js";

export function NavBar() {
    let elems_8;
    const currentUrl = RouterModule_urlSegments(window.location.hash, 1);
    const buttonStyle = ofArray([["className", "is-dark"], ["className", "is-rounded"], ["className", "is-outlined"]]);
    const xs_14 = [createElement("div", createObj(ofArray([["className", "is-flex is-justify-content-center mt-3 mb-5"], (elems_8 = [createElement("a", createObj(Helpers_combineClasses("button", toList(delay(() => append(buttonStyle, delay(() => append(isEmpty(currentUrl) ? singleton(["className", "mr-3 is-hovered"]) : singleton(["className", "mr-3"]), delay(() => append(singleton(["href", "#"]), delay(() => {
        let elems_1, elms;
        return singleton((elems_1 = [(elms = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fas fa-home"])), [])), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), createElement("span", {
            children: ["Home"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))))))))))), createElement("a", createObj(Helpers_combineClasses("button", toList(delay(() => append(buttonStyle, delay(() => {
        let matchValue_1;
        return append((matchValue_1 = currentUrl, !isEmpty(matchValue_1) ? ((head(matchValue_1) === "monitoring") ? singleton(["className", "mr-3 is-hovered"]) : singleton(["className", "mr-3"])) : singleton(["className", "mr-3"])), delay(() => append(singleton(["href", "#monitoring"]), delay(() => {
            let elems_3, elms_1;
            return singleton((elems_3 = [(elms_1 = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fas fa-desktop"])), [])), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            })), createElement("span", {
                children: ["Monitoring"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))]));
        }))));
    }))))))), createElement("a", createObj(Helpers_combineClasses("button", toList(delay(() => append(buttonStyle, delay(() => append(singleton(["className", "mr-3"]), delay(() => append(singleton(["href", "#supplier-catalog"]), delay(() => {
        let elems_5, elms_2;
        return singleton((elems_5 = [(elms_2 = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fas fa-truck"])), [])), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), createElement("span", {
            className: "ml-1",
            children: "Supplier Catalog",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
    }))))))))))), createElement("a", createObj(Helpers_combineClasses("button", toList(delay(() => append(buttonStyle, delay(() => append(singleton(["href", "#consumer-catalog"]), delay(() => {
        let elems_7, elms_3;
        return singleton((elems_7 = [(elms_3 = singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, ["fas fa-shopping-cart"])), [])), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), createElement("span", {
            children: ["Consumer Catalog"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]));
    })))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))];
    return react.createElement(react.Fragment, {}, ...xs_14);
}

export function OperationsPage() {
    let elems_4, elems_2, elems, elems_1, elems_3;
    return createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-ancestor"], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_2 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems = [createElement(InventorySection, null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], (elems_1 = [createElement(BottleStorySection, null)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-parent"], ["className", "is-vertical"], (elems_3 = [createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], ["children", createElement(SalesSection, null)]])))), createElement("div", createObj(Helpers_combineClasses("tile", ofArray([["className", "is-child"], ["children", createElement(TemperatureSection, null)]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
}

export function HomePage(homePageInputProps) {
    const onLogout = homePageInputProps.onLogout;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    const nodeEnv = process.env["NODE_ENV"] ? process.env["NODE_ENV"] : '';
    const isProd = nodeEnv === "production";
    return createElement("div", createObj(toList(delay(() => append(!isProd ? singleton(["className", "has-background-grey-lighter"]) : empty(), delay(() => {
        let elems_8, elems_4, elms, elems_1, elems, elems_2, elems_7, elements, elms_1, elems_5;
        return singleton((elems_8 = [createElement("div", createObj(Helpers_combineClasses("container", ofArray([["className", "is-max-widescreen"], (elems_4 = [(elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", empty_1()))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-8"], (elems_1 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-2 my-5 has-text-centered"], (elems = toList(delay(() => append(singleton("Crazy Wild Wines"), delay(() => (!isProd ? singleton(createElement("span", createObj(Helpers_combineClasses("tag", ofArray([["className", "ml-3 is-large is-danger is-danger"], ["children", nodeEnv]]))))) : empty()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["style", {
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
        }], (elems_2 = [createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-white"], ["onClick", (_arg) => {
            onLogout();
        }], ["children", "Log out"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("div", createObj(Helpers_combineClasses("container", singleton_1((elems_7 = [createElement(NavBar, null), RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => (!isEmpty(currentUrl) ? ((head(currentUrl) === "monitoring") ? (isEmpty(tail(currentUrl)) ? singleton(createElement(MonitoringPage, null)) : singleton(createElement("h1", {
            children: ["Not found"],
        }))) : singleton(createElement("h1", {
            children: ["Not found"],
        }))) : singleton(createElement(OperationsPage, null))))), ["application", react.createElement(react.Fragment, {}, ...elements)])]))), (elms_1 = singleton_1(createElement("div", createObj(ofArray([["className", "content has-text-centered"], (elems_5 = [createElement("b", {
            children: ["Crazy Wild Wines - Dashboard"],
        }), " by Le Curryfieur"], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("footer", {
            className: "footer",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]));
    }))))));
}

