import { join } from "../fable-library.4.3.0/String.js";
import { ofArray, choose } from "../fable-library.4.3.0/List.js";
import { toString } from "../fable-library.4.3.0/Types.js";

export function op_PlusPlus(prop1, prop2) {
    return ["className", join(" ", choose((arg) => {
        const _arg = arg;
        if (_arg[0] === "className") {
            return toString(_arg[1]);
        }
        else {
            return void 0;
        }
    }, ofArray([prop1, prop2])))];
}

