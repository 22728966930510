import * as global$ from "./styles/global.scss";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { LoginPage, useAuth } from "./Login.fs.js";
import { createObj } from "./fable_modules/fable-library.4.3.0/Util.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { CatalogType, Catalog } from "./Catalog.fs.js";
import { HomePage } from "./App.fs.js";
import { ofArray, tail, head, isEmpty } from "./fable_modules/fable-library.4.3.0/List.js";
import { render } from "react-dom";


export function Router() {
    let elements;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const authResult = useAuth();
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => {
        if (authResult.state.tag === 1) {
            return singleton(createElement(LoginPage, {
                onLogin: authResult.onLogin,
            }));
        }
        else {
            const matchValue_1 = patternInput[0];
            let matchResult, clientName, otherwise;
            if (isEmpty(matchValue_1)) {
                matchResult = 3;
            }
            else {
                switch (head(matchValue_1)) {
                    case "consumer-catalog": {
                        if (isEmpty(tail(matchValue_1))) {
                            matchResult = 0;
                        }
                        else {
                            matchResult = 4;
                            otherwise = matchValue_1;
                        }
                        break;
                    }
                    case "supplier-catalog": {
                        if (!isEmpty(tail(matchValue_1))) {
                            if (isEmpty(tail(tail(matchValue_1)))) {
                                matchResult = 2;
                                clientName = head(tail(matchValue_1));
                            }
                            else {
                                matchResult = 4;
                                otherwise = matchValue_1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                        break;
                    }
                    case "monitoring": {
                        if (isEmpty(tail(matchValue_1))) {
                            matchResult = 3;
                        }
                        else {
                            matchResult = 4;
                            otherwise = matchValue_1;
                        }
                        break;
                    }
                    default: {
                        matchResult = 4;
                        otherwise = matchValue_1;
                    }
                }
            }
            switch (matchResult) {
                case 0:
                    return singleton(createElement(Catalog, {
                        catalogType: new CatalogType(0, []),
                    }));
                case 1:
                    return singleton(createElement(Catalog, {
                        catalogType: new CatalogType(1, []),
                    }));
                case 2:
                    return singleton(createElement(Catalog, {
                        catalogType: new CatalogType(2, [clientName]),
                    }));
                case 3:
                    return singleton(createElement(HomePage, {
                        onLogout: authResult.onLogout,
                    }));
                default:
                    return singleton(createElement("h1", {
                        children: ["Not found"],
                    }));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

render(createElement(Router, null), document.getElementById("feliz-app"));

