import { Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { array_type, anonRecord_type, record_type, int32_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { substring, printf, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { systemGqlQuery } from "./Hooks.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";

export class LoginResponse extends Record {
    constructor(access_token, expires, refresh_token) {
        super();
        this.access_token = access_token;
        this.expires = (expires | 0);
        this.refresh_token = refresh_token;
    }
}

export function LoginResponse_$reflection() {
    return record_type("CwwDashboard.Auth.LoginResponse", [], LoginResponse, () => [["access_token", string_type], ["expires", int32_type], ["refresh_token", string_type]]);
}

export class GqlResponse$1 extends Record {
    constructor(data) {
        super();
        this.data = data;
    }
}

export function GqlResponse$1_$reflection(gen0) {
    return record_type("CwwDashboard.Auth.GqlResponse`1", [gen0], GqlResponse$1, () => [["data", gen0]]);
}

export class GqlErrorResponse extends Record {
    constructor(errors) {
        super();
        this.errors = errors;
    }
}

export function GqlErrorResponse_$reflection() {
    return record_type("CwwDashboard.Auth.GqlErrorResponse", [], GqlErrorResponse, () => [["errors", array_type(anonRecord_type(["message", string_type]))]]);
}

export function login(email, password) {
    return singleton.Delay(() => {
        const mutation = toText(printf("\r\n        mutation {\r\n          auth_login(email: \"%s\", password: \"%s\") {\r\n            access_token\r\n            expires\r\n            refresh_token\r\n          }\r\n        }"))(email)(password);
        return singleton.Bind(systemGqlQuery(mutation), (_arg) => {
            let matchValue, matchValue_3;
            const statusCode = _arg[0] | 0;
            const body = _arg[1];
            let parseResult;
            try {
                parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(body), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(GqlResponse$1_$reflection(anonRecord_type(["auth_login", LoginResponse_$reflection()])))) : (() => {
                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                })())]));
            }
            catch (ex) {
                parseResult = (new FSharpResult$2(1, [ex.message]));
            }
            if (statusCode === 200) {
                const copyOfStruct = parseResult;
                return (copyOfStruct.tag === 1) ? singleton.Return((matchValue_3 = (() => {
                    let matchValue_2;
                    try {
                        return new FSharpResult$2(0, [(matchValue_2 = SimpleJson_tryParse(body), (matchValue_2 != null) ? Convert_fromJson(matchValue_2, createTypeInfo(GqlErrorResponse_$reflection())) : (() => {
                            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                        })())]);
                    }
                    catch (ex_1) {
                        return new FSharpResult$2(1, [ex_1.message]);
                    }
                })(), (matchValue_3.tag === 1) ? (new FSharpResult$2(1, [matchValue_3.fields[0]])) : (new FSharpResult$2(1, [matchValue_3.fields[0].errors[0].message])))) : singleton.Return(new FSharpResult$2(0, [copyOfStruct.fields[0].data.auth_login]));
            }
            else {
                return singleton.Return(new FSharpResult$2(1, [`Request error - Code: ${statusCode}, Response: ${substring(body, 0, 1000)}`]));
            }
        });
    });
}

