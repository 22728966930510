import { createElement } from "react";
import React from "react";
import { equals, createObj } from "../fable-library.4.3.0/Util.js";
import { Layout_wrapper, Hook_useOnClickOutside, Html_divClassed } from "./Extensions.fs.js";
import { join, printf, toText } from "../fable-library.4.3.0/String.js";
import { cons, singleton as singleton_1, ofArray } from "../fable-library.4.3.0/List.js";
import { Interop_reactApi } from "../Feliz.2.7.0/./Interop.fs.js";
import { minutes, hours, fromHours, fromMinutes, add } from "../fable-library.4.3.0/TimeSpan.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useElementRef } from "../Feliz.2.7.0/React.fs.js";
import { defaultArg, value as value_30, toArray, map } from "../fable-library.4.3.0/Option.js";
import { empty, singleton, append, delay, toList, iterate } from "../fable-library.4.3.0/Seq.js";
import { defaultOf } from "../Feliz.2.7.0/../fable-library.4.3.0/Util.js";
import { Helpers_combineClasses } from "../Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { op_PlusPlus } from "../Feliz.Bulma.3.0.0/Operators.fs.js";

function TimePicker_safeHours(hrs) {
    if (hrs < 0) {
        return 23;
    }
    else if (hrs > 23) {
        return 0;
    }
    else {
        return hrs | 0;
    }
}

function TimePicker_safeMinutes(mins) {
    if (mins < 0) {
        return 59;
    }
    else if (mins > 59) {
        return 0;
    }
    else {
        return mins | 0;
    }
}

export function TimePicker_timePickerValue(timePicker_timePickerValueInputProps) {
    let elems;
    const onDataChanged = timePicker_timePickerValueInputProps.onDataChanged;
    const count = timePicker_timePickerValueInputProps.count;
    const fixFn = timePicker_timePickerValueInputProps.fixFn;
    const cn = timePicker_timePickerValueInputProps.cn;
    return createElement("div", createObj(ofArray([["className", cn], (elems = [createElement("span", {
        className: "timepicker-next",
        children: "+",
        onClick: (_arg) => {
            onDataChanged(fixFn(count + 1));
        },
    }), Html_divClassed("timepicker-input", ofArray([createElement("input", {
        type: "number",
    }), createElement("div", {
        className: "timepicker-input-number",
        children: toText(printf("%02d"))(count),
    })])), createElement("span", {
        className: "timepicker-previous",
        children: "-",
        onClick: (_arg_1) => {
            onDataChanged(fixFn(count - 1));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function TimePicker_timePickerHours(defVal, onDataChanged) {
    return createElement(TimePicker_timePickerValue, {
        cn: "timepicker-hours",
        fixFn: TimePicker_safeHours,
        count: defVal,
        onDataChanged: onDataChanged,
    });
}

export function TimePicker_timePickerMinutes(defVal, onDataChanged) {
    return createElement(TimePicker_timePickerValue, {
        cn: "timepicker-minutes",
        fixFn: TimePicker_safeMinutes,
        count: defVal,
        onDataChanged: onDataChanged,
    });
}

export function TimePicker_toTimeSpans(hs, ms, he, me) {
    return [add(fromHours(hs), fromMinutes(ms)), add(fromHours(he), fromMinutes(me))];
}

export function TimePicker_toHrsMins(ts, te) {
    return [hours(ts), minutes(ts), hours(te), minutes(te)];
}

export function TimePicker_toFormattedTime(s) {
    const arg = hours(s) | 0;
    const arg_1 = minutes(s) | 0;
    return toText(printf("%02d:%02d"))(arg)(arg_1);
}

export function TimePicker_timePicker(timePicker_timePickerInputProps) {
    let matchValue_2, elems_1, value_15, elems;
    const p = timePicker_timePickerInputProps.p;
    const myRef = useReact_useElementRef();
    const patternInput = useFeliz_React__React_useState_Static_1505(p.isRange ? p.defaultRangeValue : map((x) => [x, x], p.defaultValue));
    const time = patternInput[0];
    let patternInput_3;
    const matchValue = p.displayMode;
    if (matchValue.tag === 1) {
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
        patternInput_3 = [true, (value) => {
        }];
    }
    else {
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
        const displayed = patternInput_1[0];
        patternInput_3 = [displayed, (v) => {
            let matchResult;
            if (displayed) {
                if (v) {
                    matchResult = 2;
                }
                else {
                    matchResult = 0;
                }
            }
            else if (v) {
                matchResult = 1;
            }
            else {
                matchResult = 2;
            }
            switch (matchResult) {
                case 0: {
                    iterate((handler) => {
                        handler();
                    }, toArray(p.onHide));
                    break;
                }
                case 1: {
                    iterate((handler_1) => {
                        handler_1();
                    }, toArray(p.onShow));
                    break;
                }
            }
            patternInput_1[1](v);
        }];
    }
    const setIsDisplayed = patternInput_3[1];
    Hook_useOnClickOutside(myRef, (_arg) => {
        setIsDisplayed(false);
    });
    const updateTime = (t) => {
        patternInput[1](t);
        if (p.isRange && (p.onTimeRangeSelected != null)) {
            value_30(p.onTimeRangeSelected)(t);
        }
        if (!p.isRange && (p.onTimeSelected != null)) {
            value_30(p.onTimeSelected)(map((tuple) => tuple[0], t));
        }
    };
    let patternInput_4;
    const tupledArg = defaultArg(time, [0, 0]);
    patternInput_4 = TimePicker_toHrsMins(tupledArg[0], tupledArg[1]);
    const sm = patternInput_4[1] | 0;
    const sh = patternInput_4[0] | 0;
    const em = patternInput_4[3] | 0;
    const eh = patternInput_4[2] | 0;
    const body = ofArray([Html_divClassed("datetimepicker-container", singleton_1(Html_divClassed("timepicker", toList(delay(() => append(singleton(Html_divClassed("timepicker-start", ofArray([TimePicker_timePickerHours(sh, (i) => {
        let tupledArg_1;
        updateTime((tupledArg_1 = [i, sm, eh, em], TimePicker_toTimeSpans(tupledArg_1[0], tupledArg_1[1], tupledArg_1[2], tupledArg_1[3])));
    }), Html_divClassed("timepicker-time-divider", singleton_1(":")), TimePicker_timePickerMinutes(sm, (i_1) => {
        let tupledArg_2;
        updateTime((tupledArg_2 = [sh, i_1, eh, em], TimePicker_toTimeSpans(tupledArg_2[0], tupledArg_2[1], tupledArg_2[2], tupledArg_2[3])));
    })]))), delay(() => (p.isRange ? singleton(Html_divClassed("timepicker-end", ofArray([TimePicker_timePickerHours(eh, (i_2) => {
        let tupledArg_3;
        updateTime((tupledArg_3 = [sh, sm, i_2, em], TimePicker_toTimeSpans(tupledArg_3[0], tupledArg_3[1], tupledArg_3[2], tupledArg_3[3])));
    }), Html_divClassed("timepicker-time-divider", singleton_1(":")), TimePicker_timePickerMinutes(em, (i_3) => {
        let tupledArg_4;
        updateTime((tupledArg_4 = [sh, sm, eh, i_3], TimePicker_toTimeSpans(tupledArg_4[0], tupledArg_4[1], tupledArg_4[2], tupledArg_4[3])));
    })]))) : empty())))))))), Html_divClassed("datetimepicker-footer", singleton_1(createElement("button", {
        className: join(" ", ["datetimepicker-footer-clear", "has-text-danger", "button", "is-small", "is-text"]),
        type: "button",
        children: p.clearLabel,
        onClick: (_arg_1) => {
            updateTime(void 0);
        },
    })))]);
    const txtValue = defaultArg(p.isRange ? map((tupledArg_5) => {
        const arg_4 = TimePicker_toFormattedTime(tupledArg_5[0]);
        const arg_5 = TimePicker_toFormattedTime(tupledArg_5[1]);
        return toText(printf("%s - %s"))(arg_4)(arg_5);
    }, time) : map(TimePicker_toFormattedTime, map((tuple_1) => tuple_1[0], time)), "");
    const children = ofArray([(matchValue_2 = p.displayMode, (matchValue_2.tag === 1) ? defaultOf() : createElement("div", createObj(Helpers_combineClasses("control", ofArray([["className", "has-icons-left"], (elems_1 = [createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["readOnly", true], (value_15 = txtValue, ["ref", (e) => {
        if (!(e == null) && !equals(e.value, value_15)) {
            e.value = value_15;
        }
    }]), ["onClick", (_arg_2) => {
        setIsDisplayed(true);
    }]]))))), createElement("span", createObj(Helpers_combineClasses("icon", ofArray([["style", {
        zIndex: 0,
    }], ["className", "is-left"], (elems = [createElement("i", createObj(singleton_1(op_PlusPlus(["className", "far fa-clock"], ["className", "has-text-grey"]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))), Layout_wrapper(patternInput_3[0], p.displayMode, myRef, body)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

