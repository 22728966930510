import { Union, Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { union_type, record_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { createObj, equals, defaultOf } from "./fable_modules/fable-library.4.3.0/Util.js";
import { Result_Map, FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_serialize, Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { useReact_useEffect_3A5B6456, useReact_useCallback_1CA17B65, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { createElement } from "react";
import React from "react";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { singleton as singleton_1, cons, empty, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { startImmediate } from "./fable_modules/fable-library.4.3.0/Async.js";
import { singleton } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { login } from "./Auth.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { singleton as singleton_2, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";

export class UserInfoDto extends Record {
    constructor(jwt, email) {
        super();
        this.jwt = jwt;
        this.email = email;
    }
}

export function UserInfoDto_$reflection() {
    return record_type("Login.UserInfoDto", [], UserInfoDto, () => [["jwt", string_type], ["email", string_type]]);
}

export class UserInfo extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Logged", "Unlogged"];
    }
}

export function UserInfo_$reflection() {
    return union_type("Login.UserInfo", [], UserInfo, () => [[["jwt", string_type], ["email", string_type]], []]);
}

export function getAuthData() {
    let matchValue_1;
    const value = localStorage.getItem("userInfo");
    matchValue_1 = ((value === defaultOf()) ? (new FSharpResult$2(0, [void 0])) : Result_Map((arg) => arg, (() => {
        let matchValue;
        try {
            return new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(value), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(UserInfoDto_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]);
        }
        catch (ex) {
            return new FSharpResult$2(1, [ex.message]);
        }
    })()));
    if (matchValue_1.tag === 1) {
        return new UserInfo(1, []);
    }
    else if (matchValue_1.fields[0] != null) {
        const data = matchValue_1.fields[0];
        return new UserInfo(0, [data.jwt, data.email]);
    }
    else {
        return new UserInfo(1, []);
    }
}

export function useAuth() {
    const authData = getAuthData();
    const patternInput = useFeliz_React__React_useState_Static_1505(authData);
    const state = patternInput[0];
    const setState = patternInput[1];
    const onLogin = useReact_useCallback_1CA17B65((userInfo) => {
        localStorage.setItem("userInfo", Convert_serialize(userInfo, createTypeInfo(UserInfoDto_$reflection())));
        setState(new UserInfo(0, [userInfo.jwt, userInfo.email]));
    }, []);
    const onLogout = useReact_useCallback_1CA17B65(() => {
        localStorage.removeItem("userInfo");
        setState(new UserInfo(1, []));
    }, []);
    useReact_useEffect_3A5B6456(() => {
        if (!equals(authData, state)) {
            setState(authData);
        }
    });
    return {
        onLogin: onLogin,
        onLogout: onLogout,
        state: state,
    };
}

export function LoginPage(loginPageInputProps) {
    let elems_8, elems_7, elems_6, elms_1, elms, elms_3, elms_2, elems_5, elms_4;
    const onLogin = loginPageInputProps.onLogin;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const email = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const password = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(defaultOf());
    const setError = patternInput_2[1];
    const error = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsSubmitting = patternInput_3[1];
    const elms_5 = ofArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "has-text-centered mt-6"], ["children", "Sign in"]])))), createElement("div", createObj(Helpers_combineClasses("columns", singleton_1((elems_8 = [createElement("div", createObj(Helpers_combineClasses("column", empty()))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-4"], ["className", "mx-5"], (elems_7 = [createElement("form", createObj(ofArray([["onSubmit", (e) => {
        startImmediate(singleton.Delay(() => {
            e.preventDefault();
            setError(defaultOf());
            setIsSubmitting(true);
            return singleton.Bind(login(email, password), (_arg) => {
                const result = _arg;
                setIsSubmitting(false);
                if (result.tag === 1) {
                    setError(result.fields[0]);
                    return singleton.Zero();
                }
                else {
                    onLogin(new UserInfoDto(result.fields[0].access_token, email));
                    return singleton.Zero();
                }
            });
        }));
    }], (elems_6 = [(elms_1 = ofArray([createElement("label", {
        className: "label",
        children: "Email",
    }), (elms = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["className", "is-light"], ["placeholder", "Email"], ["required", true], ["value", email], ["onChange", (ev) => {
        patternInput[1](ev.target.value);
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_3 = ofArray([createElement("label", {
        className: "label",
        children: "Password",
    }), (elms_2 = singleton_1(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["className", "is-light"], ["placeholder", "Password"], ["required", true], ["value", password], ["onChange", (ev_1) => {
        patternInput_1[1](ev_1.target.value);
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), createElement("p", createObj(toList(delay(() => append(singleton_2(["style", {
        height: 2 + "em",
    }]), delay(() => append(singleton_2(["className", "has-text-danger"]), delay(() => ((error !== defaultOf()) ? singleton_2(["children", error]) : singleton_2(["children", ""])))))))))), createElement("div", createObj(Helpers_combineClasses("field", ofArray([["className", "is-grouped"], ["className", "is-grouped-right"], (elems_5 = [(elms_4 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-link"], ["disabled", ((email.trim().length === 0) && (password.trim().length === 0)) ? true : patternInput_3[0]], ["children", "Sign in"]]))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))), createElement("div", createObj(Helpers_combineClasses("column", empty())))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    });
}

