import { Union } from "../fable-library.4.3.0/Types.js";
import { union_type } from "../fable-library.4.3.0/Reflection.js";
import { useReact_useEffect_7331F961 } from "../Feliz.2.7.0/React.fs.js";
import { value as value_8 } from "../fable-library.4.3.0/Option.js";
import { createElement } from "react";
import { Interop_reactApi } from "../Feliz.2.7.0/./Interop.fs.js";
import { createObj } from "../fable-library.4.3.0/Util.js";
import { empty, singleton, append, delay, toList } from "../fable-library.4.3.0/Seq.js";
import { join } from "../fable-library.4.3.0/String.js";
import { ofArray } from "../fable-library.4.3.0/List.js";

export class DisplayMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Default", "Inline"];
    }
}

export function DisplayMode_$reflection() {
    return union_type("Feliz.Bulma.DisplayMode", [], DisplayMode, () => [[], []]);
}

export function Hook_useOnClickOutside(ref, handler) {
    useReact_useEffect_7331F961(() => {
        const listener = (event) => {
            let copyOfStruct;
            if ((ref.current == null) ? true : ((copyOfStruct = value_8(ref.current), copyOfStruct.contains(event.target)))) {
            }
            else {
                handler(event);
            }
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return {
            Dispose() {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            },
        };
    }, [ref, handler]);
}

export function Html_divClassed(cn, cont) {
    return createElement("div", {
        className: cn,
        children: Interop_reactApi.Children.toArray(Array.from(cont)),
    });
}

export function Layout_wrapper(isDisplayed, mode, ref, cont) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", "datetimepicker-wrapper"], (elems_1 = [createElement("div", createObj(toList(delay(() => append(singleton(["className", join(" ", toList(delay(() => append(singleton("datetimepicker"), delay(() => append((mode.tag === 1) ? singleton("") : singleton("is-datetimepicker-default"), delay(() => append(singleton("is-primary"), delay(() => (isDisplayed ? singleton("is-active") : empty()))))))))))]), delay(() => append((mode.tag === 0) ? singleton(["style", {
        position: "absolute",
    }]) : (empty()), delay(() => singleton(["children", Interop_reactApi.Children.toArray(Array.from(cont))])))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]), ["ref", ref]])));
}

