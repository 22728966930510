import { toString as toString_1, Union, Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { bool_type, union_type, option_type, record_type, array_type, anonRecord_type, class_type, int32_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { ofList, ofSeq as ofSeq_1, FSharpMap__get_Item, FSharpMap__TryFind, toArray as toArray_1, empty, ofArray } from "./fable_modules/fable-library.4.3.0/Map.js";
import { pick, sumBy, enumerateWhile, toList, choose, append, empty as empty_1, map, singleton, collect, delay, toArray } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { compareArrays, min, int32ToString, defaultOf, equals, createObj, compare, comparePrimitives } from "./fable_modules/fable-library.4.3.0/Util.js";
import { create, equals as equals_1, addDays, compare as compare_1, day, month as month_1, year as year_1, today as today_1, toString } from "./fable_modules/fable-library.4.3.0/Date.js";
import { join, compare as compare_2, interpolate, printf, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.4.3.0/AsyncBuilder.js";
import { useGetQueryWithRefresh, QueryResponse$1, useGql, useGetQuery, gqlQuery } from "./Hooks.fs.js";
import { Result_Map, FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { ofArray as ofArray_2, map as map_3, difference, union, intersect, filter, ofSeq } from "./fable_modules/fable-library.4.3.0/Set.js";
import { sum, sortBy, tryFind, collect as collect_1, sortWith, reverse, tryItem, map as map_1 } from "./fable_modules/fable-library.4.3.0/Array.js";
import { Result_defaultValue, LoadingElement, KPIS_ENDPOINT, String_join } from "./Common.fs.js";
import { bind, unwrap, defaultArg, map as map_2 } from "./fable_modules/fable-library.4.3.0/Option.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useReact_useMemo_10C6A43C, useReact_useEffect_311B4086, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { startImmediate } from "./fable_modules/fable-library.4.3.0/Async.js";
import { cons, map as map_4, exists, empty as empty_2, ofArray as ofArray_1, singleton as singleton_2 } from "./fable_modules/fable-library.4.3.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { endOfMonth, subMonths, format as format_3 } from "date-fns";
import { WarningMessage } from "./Components.fs.js";
import { Http_post } from "./fable_modules/Fable.SimpleHttp.3.0.0/Http.fs.js";
import { useWineBottles } from "./DataSources.fs.js";
import { DisplayMode as DisplayMode_1 } from "./fable_modules/Feliz.Bulma.DateTimePicker.3.0.0/Extensions.fs.js";
import { DatePicker_dateTimePicker } from "./fable_modules/Feliz.Bulma.DateTimePicker.3.0.0/./DateTime.fs.js";
import { defaultOf as defaultOf_1 } from "./fable_modules/Feliz.2.7.0/../fable-library.4.3.0/Util.js";

class Types_BottleStock extends Record {
    constructor(bottle, stocks) {
        super();
        this.bottle = bottle;
        this.stocks = stocks;
    }
}

function Types_BottleStock_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.Types.BottleStock", [], Types_BottleStock, () => [["bottle", string_type], ["stocks", array_type(anonRecord_type(["count", int32_type], ["purchaseDate", class_type("System.DateTime")]))]]);
}

class Types_GlobalInventoryResponse extends Record {
    constructor(consolidatedInventory, distributedInventory, breakdownByPurchase) {
        super();
        this.consolidatedInventory = consolidatedInventory;
        this.distributedInventory = distributedInventory;
        this.breakdownByPurchase = breakdownByPurchase;
    }
}

function Types_GlobalInventoryResponse_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.Types.GlobalInventoryResponse", [], Types_GlobalInventoryResponse, () => [["consolidatedInventory", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])], ["distributedInventory", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])])], ["breakdownByPurchase", array_type(Types_BottleStock_$reflection())]]);
}

class Types_WarehouseIvtDiff extends Record {
    constructor(warehouse, commonData, diffData) {
        super();
        this.warehouse = warehouse;
        this.commonData = commonData;
        this.diffData = diffData;
    }
}

function Types_WarehouseIvtDiff_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.Types.WarehouseIvtDiff", [], Types_WarehouseIvtDiff, () => [["warehouse", string_type], ["commonData", array_type(anonRecord_type(["bottleName", string_type], ["stock", int32_type]))], ["diffData", array_type(anonRecord_type(["bottleName", string_type], ["leftStock", option_type(int32_type)], ["rightStock", option_type(int32_type)]))]]);
}

export class InventoryMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Origin", "LastSnapshot"];
    }
}

export function InventoryMode_$reflection() {
    return union_type("CwwDashboard.Sections.Inventory.InventoryMode", [], InventoryMode, () => [[], []]);
}

const colorClasses = ["is-info", "is-primary", "is-warning", "is-success", "is-dark", "is-danger"];

class GlobalInventorySnapshotsDto extends Record {
    constructor(date, status, warehouse_inventory_snapshots) {
        super();
        this.date = date;
        this.status = status;
        this.warehouse_inventory_snapshots = warehouse_inventory_snapshots;
    }
}

function GlobalInventorySnapshotsDto_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.GlobalInventorySnapshotsDto", [], GlobalInventorySnapshotsDto, () => [["date", class_type("System.DateTime")], ["status", string_type], ["warehouse_inventory_snapshots", array_type(anonRecord_type(["is_complete", bool_type], ["items", array_type(anonRecord_type(["count", int32_type], ["wine_bottle", anonRecord_type(["invoice_name", string_type])]))], ["warehouse", anonRecord_type(["name", string_type])]))]]);
}

function GlobalInventorySnapshotsDto__ToGlobalInventoryResponse(x) {
    const distributedIvt = ofArray(toArray(delay(() => collect((whIvtSnapshot) => singleton([whIvtSnapshot.warehouse.name, ofArray(toArray(delay(() => map((item) => [item.wine_bottle.invoice_name, item.count], whIvtSnapshot.items))), {
        Compare: comparePrimitives,
    })]), x.warehouse_inventory_snapshots))), {
        Compare: comparePrimitives,
    });
    return new Types_GlobalInventoryResponse(empty({
        Compare: comparePrimitives,
    }), distributedIvt, []);
}

function getLatestWarehouseIvtSnapshot(date, warehouseName) {
    let query;
    const arg_1 = toString(date, "yyyy-MM-dd");
    query = toText(printf("\r\n      query {\r\n        global_inventory_snapshots(\r\n          filter: {\r\n            warehouse_inventory_snapshots: { warehouse: { name: { _eq: \"%s\" } } }\r\n            date: { _lte: \"%s\" }\r\n          }\r\n          sort: \"-date\"\r\n          limit: 1\r\n        ) {\r\n          date\r\n          status\r\n          warehouse_inventory_snapshots {\r\n            is_complete\r\n            warehouse {\r\n              name\r\n            }\r\n            items {\r\n              wine_bottle { invoice_name }\r\n              count\r\n            }\r\n          }\r\n        }\r\n      }"))(warehouseName)(arg_1);
    return singleton_1.Delay(() => singleton_1.Bind(gqlQuery(query), (_arg) => {
        let matchValue, copyOfStruct;
        const statusCode = _arg[0] | 0;
        const body = _arg[1];
        let response;
        try {
            response = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(body), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["data", anonRecord_type(["global_inventory_snapshots", array_type(GlobalInventorySnapshotsDto_$reflection())])]))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            response = (new FSharpResult$2(1, [ex.message]));
        }
        return singleton_1.Return((statusCode === 200) ? ((copyOfStruct = response, (copyOfStruct.tag === 1) ? (new FSharpResult$2(1, [`Error while parsing response - Body=${copyOfStruct.fields[0]}`])) : (new FSharpResult$2(0, [copyOfStruct.fields[0].data])))) : (new FSharpResult$2(1, [`Error - Code=${statusCode} - Body=${body}`])));
    }));
}

export class GlobalInventorySnapshot extends Record {
    constructor(date, warehouseSnapshots) {
        super();
        this.date = date;
        this.warehouseSnapshots = warehouseSnapshots;
    }
}

export function GlobalInventorySnapshot_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.GlobalInventorySnapshot", [], GlobalInventorySnapshot, () => [["date", class_type("System.DateTime")], ["warehouseSnapshots", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])])]]);
}

function computeIvtDiff(left, right) {
    const toSetOfKeys = (x) => ofSeq(map_1((tupledArg) => tupledArg[0], toArray_1(x)), {
        Compare: compare,
    });
    return toArray(delay(() => collect((matchValue) => {
        const activePatternResult = matchValue;
        const wh = activePatternResult[0];
        const leftIvt = activePatternResult[1];
        let rightIvt;
        const matchValue_1 = FSharpMap__TryFind(right.distributedInventory, wh);
        rightIvt = ((matchValue_1 == null) ? empty({
            Compare: comparePrimitives,
        }) : matchValue_1);
        const leftIvtKeys = toSetOfKeys(leftIvt);
        const rightIvtKeys = toSetOfKeys(rightIvt);
        const commonKeys = filter((key) => {
            if (FSharpMap__get_Item(leftIvt, key) > 0) {
                return true;
            }
            else {
                return FSharpMap__get_Item(rightIvt, key) > 0;
            }
        }, intersect(leftIvtKeys, rightIvtKeys));
        const diffKeys = union(difference(leftIvtKeys, rightIvtKeys), difference(rightIvtKeys, leftIvtKeys));
        return singleton(new Types_WarehouseIvtDiff(wh, toArray(delay(() => collect((key_1) => {
            const matchValue_2 = FSharpMap__get_Item(leftIvt, key_1) | 0;
            if (matchValue_2 === FSharpMap__get_Item(rightIvt, key_1)) {
                return singleton({
                    bottleName: key_1,
                    stock: matchValue_2,
                });
            }
            else {
                return empty_1();
            }
        }, commonKeys))), toArray(delay(() => append(collect((key_2) => {
            const matchValue_5 = FSharpMap__get_Item(leftIvt, key_2) | 0;
            const rightStock = FSharpMap__get_Item(rightIvt, key_2) | 0;
            const leftStock = matchValue_5 | 0;
            return (leftStock !== rightStock) ? singleton({
                bottleName: key_2,
                leftStock: leftStock,
                rightStock: rightStock,
            }) : empty_1();
        }, commonKeys), delay(() => collect((key_3) => {
            const matchValue_7 = FSharpMap__TryFind(leftIvt, key_3);
            const matchValue_8 = FSharpMap__TryFind(rightIvt, key_3);
            let matchResult, leftStock_2, apiStock_3;
            if (matchValue_7 == null) {
                if (matchValue_8 != null) {
                    if (matchValue_8 > 0) {
                        matchResult = 1;
                        apiStock_3 = matchValue_8;
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
            }
            else if (matchValue_8 == null) {
                if (matchValue_7 > 0) {
                    matchResult = 0;
                    leftStock_2 = matchValue_7;
                }
                else {
                    matchResult = 2;
                }
            }
            else {
                matchResult = 2;
            }
            switch (matchResult) {
                case 0:
                    return singleton({
                        bottleName: key_3,
                        leftStock: leftStock_2,
                    });
                case 1:
                    return singleton({
                        bottleName: key_3,
                        rightStock: apiStock_3,
                    });
                default: {
                    return empty_1();
                }
            }
        }, diffKeys)))))));
    }, left.distributedInventory)));
}

export function createWarehouseInventorySnapshot(warehouseId, date, inventory, wineBottles) {
    const wineBottleIdsByInvoiceName = ofArray(map_1((bottle) => [bottle.invoice_name, bottle.id], wineBottles.data.wine_bottles), {
        Compare: comparePrimitives,
    });
    const inventoryItems = String_join("", delay(() => append(singleton("["), delay(() => append(singleton(String_join(", ", choose((_arg_1) => {
        let tupledArg;
        const activePatternResult = _arg_1;
        const count_1 = activePatternResult[1] | 0;
        if (count_1 > 0) {
            return (tupledArg = [FSharpMap__get_Item(wineBottleIdsByInvoiceName, activePatternResult[0]), count_1], `{ wine_bottle: ${tupledArg[0]}, count: ${tupledArg[1]} }`);
        }
        else {
            return void 0;
        }
    }, inventory))), delay(() => singleton("]")))))));
    return singleton_1.Delay(() => {
        let query, arg;
        return singleton_1.Bind((query = ((arg = toString(date, "yyyy-MM-dd"), toText(printf("\r\n        query {\r\n          global_inventory_snapshots(filter: { date: { _eq: \"%s\"}}) {\r\n            id\r\n          }\r\n        }"))(arg))), singleton_1.Delay(() => singleton_1.Bind(gqlQuery(query), (_arg) => {
            let matchValue, copyOfStruct;
            const statusCode = _arg[0] | 0;
            const body = _arg[1];
            let response;
            try {
                response = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(body), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["data", anonRecord_type(["global_inventory_snapshots", array_type(anonRecord_type(["id", int32_type]))])]))) : (() => {
                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                })())]));
            }
            catch (ex) {
                response = (new FSharpResult$2(1, [ex.message]));
            }
            return singleton_1.Return((statusCode === 200) ? ((copyOfStruct = response, (copyOfStruct.tag === 1) ? (new FSharpResult$2(1, [`Error while parsing response - Body=${copyOfStruct.fields[0]}`])) : (new FSharpResult$2(0, [copyOfStruct.fields[0].data])))) : (new FSharpResult$2(1, [`Error - Code=${statusCode} - Body=${body}`])));
        }))), (_arg_2) => {
            const existingSnapshot = _arg_2;
            let globalIvtSnapshotId;
            if (existingSnapshot.tag === 1) {
                throw new Error(`Error fetching current snapshot - Msg=${existingSnapshot.fields[0]}`);
            }
            else {
                globalIvtSnapshotId = map_2((snapshot_1) => snapshot_1.id, tryItem(0, existingSnapshot.fields[0].global_inventory_snapshots));
            }
            const WarehouseIvtSnapFields = "\r\n        id\r\n        warehouse { name }\r\n        items {\r\n          wine_bottle { invoice_name }\r\n          count\r\n        }";
            if (globalIvtSnapshotId == null) {
                const today = toString(today_1(), "yyyy-MM-dd");
                return singleton_1.ReturnFrom(gqlQuery(toText(printf("\r\n            mutation {\r\n              create_global_inventory_snapshots_items(\r\n                data: {\r\n                  status: \"draft\"\r\n                  date: \"%s\"\r\n                  warehouse_inventory_snapshots: {\r\n                    warehouse: %d\r\n                    is_complete: false\r\n                    items: %s\r\n                  }\r\n                }\r\n              ) {\r\n                id\r\n                date\r\n                warehouse_inventory_snapshots { %s }\r\n              }\r\n            }"))(today)(warehouseId)(inventoryItems)(WarehouseIvtSnapFields)));
            }
            else {
                const rootId = globalIvtSnapshotId | 0;
                return singleton_1.ReturnFrom(gqlQuery(toText(printf("\r\n            mutation {\r\n              create_warehouse_inventory_snapshots_item(\r\n                data: {\r\n                  global_inventory: { id: %d }\r\n                  warehouse: %d\r\n                  is_complete: false\r\n                  items: %s\r\n                }\r\n              ) {\r\n                %s\r\n              }\r\n            }"))(rootId)(warehouseId)(inventoryItems)(WarehouseIvtSnapFields)));
            }
        });
    });
}

export function CreateSnapshotButton(createSnapshotButtonInputProps) {
    let elems_4;
    const wineBottles = createSnapshotButtonInputProps.wineBottles;
    const inventory = createSnapshotButtonInputProps.inventory;
    const warehouseId = createSnapshotButtonInputProps.warehouseId;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsLoading = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsError = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsOk = patternInput_2[1];
    return createElement("button", createObj(Helpers_combineClasses("button", ofArray_1([["className", "is-info"], ["className", "is-small"], ["className", "is-outlined"], ["className", "ml-4"], ["onClick", (_arg) => {
        setIsLoading(true);
        setIsError(false);
        setIsOk(false);
        startImmediate(singleton_1.Delay(() => singleton_1.Bind(createWarehouseInventorySnapshot(warehouseId, today_1(), inventory, wineBottles), (_arg_1) => {
            setIsLoading(false);
            if (_arg_1[0] === 200) {
                setIsOk(true);
                return singleton_1.Zero();
            }
            else {
                setIsError(true);
                return singleton_1.Zero();
            }
        })));
    }], (elems_4 = toList(delay(() => {
        let elms;
        return append(singleton((elms = singleton_2(createElement("i", {
            className: "fas fa-copy",
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => append(singleton(createElement("span", {
            children: ["create snapshot"],
        })), delay(() => {
            let elms_1;
            return append(patternInput[0] ? singleton((elms_1 = singleton_2(Fa_i(singleton_2(new Fa_IconOption(11, ["fas fa-circle-notch"])), [])), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))) : empty_1(), delay(() => {
                let elms_2;
                return append(patternInput_2[0] ? singleton((elms_2 = singleton_2(Fa_i(singleton_2(new Fa_IconOption(11, ["fas fa-check"])), [])), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))) : empty_1(), delay(() => {
                    let elms_3;
                    return patternInput_1[0] ? singleton((elms_3 = singleton_2(Fa_i(singleton_2(new Fa_IconOption(11, ["fas fa-exclamation-circle"])), [])), createElement("span", {
                        className: "icon",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))) : empty_1();
                }));
            }));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))));
}

function useGlobalInventory(mode, date) {
    let matchValue;
    const patternInput = useGetQuery(equals(mode, new InventoryMode(1, [])) ? toText(interpolate("%P()/global-inventory/end-of-day-new/%d%P()/%d%P()/%d%P()", [KPIS_ENDPOINT, year_1(date), month_1(date), day(date)])) : toText(interpolate("%P()/global-inventory/end-of-day/%d%P()/%d%P()/%d%P()", [KPIS_ENDPOINT, year_1(date), month_1(date), day(date)])));
    if (patternInput[0]) {
        return [true, void 0, defaultOf()];
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(patternInput[1]), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(Types_GlobalInventoryResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return [false, parseResult.fields[0], defaultOf()];
        }
        else {
            return [false, void 0, parseResult.fields[0]];
        }
    }
}

function BottleAnalysisRow(bottleAnalysisRowInputProps) {
    let children_10;
    const date = bottleAnalysisRowInputProps.date;
    const bottleName = bottleAnalysisRowInputProps.bottleName;
    const warehouse$0027 = bottleAnalysisRowInputProps["warehouse\'"];
    const patternInput = useGlobalInventory(new InventoryMode(0, []), date);
    const dataOrigin = patternInput[2];
    const patternInput_1 = useGlobalInventory(new InventoryMode(1, []), date);
    const dataLastSnapshot = patternInput_1[2];
    const xs_4 = [(children_10 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("td", createObj(singleton_2((elems = [format_3(date, "yyyy-MM-dd")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
            let children_4;
            let matchResult;
            if (patternInput[0]) {
                matchResult = 0;
            }
            else if (patternInput_1[0]) {
                matchResult = 0;
            }
            else if (patternInput[1] == null) {
                if (patternInput_1[1] == null) {
                    matchResult = 2;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0:
                    return append(singleton(createElement("td", {
                        children: ["..."],
                    })), delay(() => singleton(createElement("td", {
                        children: ["..."],
                    }))));
                case 1:
                    return append(singleton(createElement("td", {
                        children: Interop_reactApi.Children.toArray(["Error"]),
                    })), delay(() => singleton(createElement("td", {
                        children: Interop_reactApi.Children.toArray(["Error"]),
                    }))));
                default: {
                    let patternInput_2;
                    if (warehouse$0027 == null) {
                        patternInput_2 = [dataOrigin.consolidatedInventory, dataLastSnapshot.consolidatedInventory];
                    }
                    else {
                        const warehouse = warehouse$0027;
                        patternInput_2 = [defaultArg(FSharpMap__TryFind(dataOrigin.distributedInventory, warehouse), empty({
                            Compare: comparePrimitives,
                        })), defaultArg(FSharpMap__TryFind(dataLastSnapshot.distributedInventory, warehouse), empty({
                            Compare: comparePrimitives,
                        }))];
                    }
                    const countOrigin = defaultArg(FSharpMap__TryFind(patternInput_2[0], bottleName), 0) | 0;
                    const countLastSnapshot = defaultArg(FSharpMap__TryFind(patternInput_2[1], bottleName), 0) | 0;
                    return (countOrigin === countLastSnapshot) ? append(singleton((children_4 = singleton_2(int32ToString(countOrigin)), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    }))), delay(() => {
                        let children_6;
                        return append(singleton((children_6 = singleton_2(int32ToString(countLastSnapshot)), createElement("td", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                        }))), delay(() => singleton(createElement("td", {
                            children: Interop_reactApi.Children.toArray(["-"]),
                        }))));
                    })) : append(singleton(createElement("td", {
                        className: "has-text-danger",
                        children: int32ToString(countOrigin),
                    })), delay(() => append(singleton(createElement("td", {
                        className: "has-text-danger",
                        children: int32ToString(countLastSnapshot),
                    })), delay(() => singleton(createElement("td", createObj(toList(delay(() => append(singleton(["className", "has-text-weight-bold has-text-danger"]), delay(() => ((countLastSnapshot > countOrigin) ? singleton(["children", `+${countLastSnapshot - countOrigin}`]) : singleton(["children", `${countLastSnapshot - countOrigin}`])))))))))))));
                }
            }
        }));
    })), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    }))];
    return react.createElement(react.Fragment, {}, ...xs_4);
}

function BottleAnalysisTable(bottleAnalysisTableInputProps) {
    let elems, children_2, children, children_4;
    const bottleName = bottleAnalysisTableInputProps.bottleName;
    const warehouse = bottleAnalysisTableInputProps.warehouse;
    const endDate = bottleAnalysisTableInputProps.endDate;
    const currentEndDate = min(compare_1, endDate, today_1());
    const dates = reverse(toArray(delay(() => {
        let date_1 = subMonths(currentEndDate, 4);
        return enumerateWhile(() => (compare_1(date_1, currentEndDate) <= 0), delay(() => append(singleton(date_1), delay(() => {
            date_1 = addDays(date_1, 1);
            return empty_1();
        }))));
    })));
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray_1([["className", "is-fullwidth is-hoverable is-striped is-narrow"], (elems = [(children_2 = singleton_2((children = ofArray_1([createElement("th", {
        children: ["Date"],
    }), createElement("th", {
        children: ["Origin Count"],
    }), createElement("th", {
        children: ["Snapshot-based Count"],
    }), createElement("th", {
        children: ["Diff"],
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map((date_2) => createElement(BottleAnalysisRow, {
        "warehouse\'": unwrap(warehouse),
        bottleName: bottleName,
        date: date_2,
    }), dates))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
}

function BottleAnalysisModal(bottleAnalysisModalInputProps) {
    const closeModal = bottleAnalysisModalInputProps.closeModal;
    const bottleToAnalyze = bottleAnalysisModalInputProps.bottleToAnalyze;
    const endDate = bottleAnalysisModalInputProps.endDate;
    return createElement("div", createObj(Helpers_combineClasses("modal", toList(delay(() => append((bottleToAnalyze != null) ? singleton(["className", "is-active"]) : (empty_1()), delay(() => {
        let elems_6, elms_3;
        return singleton((elems_6 = [createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_2(["onClick", (_arg) => {
            closeModal();
        }])))), (elms_3 = toList(delay(() => {
            let elms_1, elems_2, elems_1, elms;
            return append(singleton((elms_1 = singleton_2(createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray_1([["className", "is-flex is-align-items-center is-justify-content-space-between"], (elems_2 = ["Bottle Stock Analysis", createElement("button", createObj(Helpers_combineClasses("button", ofArray_1([["className", "is-small"], ["className", "is-rounded"], ["onClick", (_arg_1) => {
                closeModal();
            }], (elems_1 = [(elms = singleton_2(Fa_i(singleton_2(new Fa_IconOption(11, ["fas fa-minus-circle"])), [])), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), createElement("span", {
                children: ["Close"],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))), createElement("header", {
                className: "modal-card-head",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), delay(() => {
                let elms_2;
                const matchValue_1 = bottleToAnalyze;
                if (matchValue_1 != null) {
                    const warehouse$0027 = matchValue_1[0];
                    const bottleName = matchValue_1[1];
                    return singleton((elms_2 = ofArray_1([createElement("h1", createObj(Helpers_combineClasses("title", toList(delay(() => append(singleton(["className", "is-4"]), delay(() => {
                        const matchValue_2 = warehouse$0027;
                        return (matchValue_2 == null) ? singleton(["children", bottleName]) : singleton(["children", `[${matchValue_2}] ${bottleName}`]);
                    }))))))), createElement(BottleAnalysisTable, {
                        endDate: endDate,
                        warehouse: unwrap(warehouse$0027),
                        bottleName: bottleName,
                    })]), createElement("section", {
                        className: "modal-card-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    })));
                }
                else {
                    return empty_1();
                }
            }));
        })), createElement("div", {
            className: "modal-content",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))]));
    })))))));
}

function Inventory(inventoryInputProps) {
    const filterEmptyStocks = inventoryInputProps.filterEmptyStocks;
    const wineBottles = inventoryInputProps.wineBottles;
    const breakdownByPurchase = inventoryInputProps.breakdownByPurchase;
    const inventory = inventoryInputProps.inventory;
    const warehouseName = inventoryInputProps.warehouseName;
    const date = inventoryInputProps.date;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const ivtSnapshot$0027 = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setBottleToAnalyze = patternInput_1[1];
    useReact_useEffect_311B4086(() => {
        startImmediate(singleton_1.Delay(() => {
            if (warehouseName == null) {
                return singleton_1.Zero();
            }
            else {
                const whName = warehouseName;
                return singleton_1.Bind(getLatestWarehouseIvtSnapshot(date, whName), (_arg) => {
                    const snapshot = _arg;
                    let matchResult;
                    if (snapshot.tag === 0) {
                        if (snapshot.fields[0].global_inventory_snapshots.length >= 1) {
                            matchResult = 0;
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            patternInput[1](snapshot.fields[0].global_inventory_snapshots[0]);
                            return singleton_1.Zero();
                        }
                        default: {
                            return singleton_1.Zero();
                        }
                    }
                });
            }
        }));
    }, [warehouseName]);
    const stockBreakdownMap = ofArray(map_1((x) => [x.bottle, x.stocks], breakdownByPurchase), {
        Compare: comparePrimitives,
    });
    const inventoryArray = sortWith((tupledArg, tupledArg_1) => compare_2(tupledArg[0], tupledArg_1[0]), toArray_1(inventory));
    const purchaseDatesColors = ofSeq_1(map_3((date_1) => [date_1, colorClasses[(((year_1(date_1) * 100) + month_1(date_1)) - 3) % colorClasses.length]], ofArray_2(collect_1((x_2) => map_1((stock) => stock.purchaseDate, x_2.stocks), breakdownByPurchase), {
        Compare: compare_1,
    }), {
        Compare: compareArrays,
    }), {
        Compare: compare_1,
    });
    const xs_20 = toList(delay(() => {
        let bottleNames;
        const negativeStocks = inventoryArray.filter((tupledArg_2) => (tupledArg_2[1] < 0));
        return append((negativeStocks.length > 0) ? ((bottleNames = join(", ", map_1((tupledArg_3) => tupledArg_3[0], negativeStocks)), singleton(createElement(WarningMessage, {
            title: "Stock is negative for some bottles!",
            children: bottleNames,
        })))) : empty_1(), delay(() => {
            let snapshot_3, whName_1, matchValue_1;
            const whDiffs$0027 = ((warehouseName == null) ? ((ivtSnapshot$0027 == null) ? [void 0, singleton_2("Nothing :(")] : [void 0, singleton_2("Nothing :(")]) : ((ivtSnapshot$0027 == null) ? [void 0, singleton_2("Nothing :(")] : ((snapshot_3 = ivtSnapshot$0027, (whName_1 = warehouseName, [computeIvtDiff(GlobalInventorySnapshotsDto__ToGlobalInventoryResponse(snapshot_3), new Types_GlobalInventoryResponse(empty({
                Compare: comparePrimitives,
            }), ofList(singleton_2([whName_1, inventory]), {
                Compare: comparePrimitives,
            }), [])), empty_2()])))))[0];
            return append((matchValue_1 = ivtSnapshot$0027, (matchValue_1 != null) ? singleton(`Last snapshot from: ${format_3(matchValue_1.date, "yyyy MMM dd")}`) : (empty_1())), delay(() => {
                let elems_6, children_2, children, children_10, children_14, children_12;
                return append(singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray_1([["className", "is-fullwidth is-hoverable is-striped is-narrow"], (elems_6 = [(children_2 = singleton_2((children = ofArray_1([createElement("th", {
                    children: ["Bottle"],
                }), createElement("th", {}), createElement("th", {
                    children: ["Count"],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                })), (children_10 = toList(delay(() => collect((matchValue_2) => {
                    let children_8;
                    const count = matchValue_2[1] | 0;
                    const bottleName = matchValue_2[0];
                    return (((count === 0) && (!filterEmptyStocks ? true : (!wineBottles.data.wine_bottles.some((bottle_2) => {
                        if (bottle_2.invoice_name === bottleName) {
                            return bottle_2.discontinued;
                        }
                        else {
                            return false;
                        }
                    }) && equals(warehouseName, "Main")))) ? true : (count !== 0)) ? singleton((children_8 = toList(delay(() => {
                        let elems;
                        return append(singleton(createElement("td", createObj(singleton_2((elems = [createElement("a", {
                            href: "#",
                            onClick: (e) => {
                                e.preventDefault();
                                setBottleToAnalyze([warehouseName, bottleName]);
                            },
                            children: bottleName,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))), delay(() => {
                            let elems_3, elems_2;
                            return append(singleton(createElement("td", createObj(ofArray_1([["className", "has-text-right is-align-items-center"], (elems_3 = [createElement("div", createObj(Helpers_combineClasses("field", ofArray_1([["className", "is-grouped is-grouped-multiline"], (elems_2 = toList(delay(() => {
                                const matchValue_3 = FSharpMap__TryFind(stockBreakdownMap, bottleName);
                                if (matchValue_3 != null) {
                                    const purchaseStocks = matchValue_3;
                                    return collect((p) => {
                                        let elems_1;
                                        return (purchaseStocks.length < 2) ? singleton(createElement("span", createObj(Helpers_combineClasses("tag", ofArray_1([["className", `${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)} is-rounded`], ["children", format_3(p.purchaseDate, "MMM yy")]]))))) : singleton(createElement("div", createObj(Helpers_combineClasses("tags", ofArray_1([["className", "has-addons mb-0 mr-2"], (elems_1 = [createElement("span", createObj(Helpers_combineClasses("tag", ofArray_1([["className", `is-rounded ${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)}`], ["children", format_3(p.purchaseDate, "MMM yy")]])))), createElement("span", createObj(Helpers_combineClasses("tag", ofArray_1([["className", `is-rounded ${FSharpMap__get_Item(purchaseDatesColors, p.purchaseDate)} has-text-weight-bold is-light`], ["children", `${p.count}`]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))));
                                    }, purchaseStocks);
                                }
                                else {
                                    return empty_1();
                                }
                            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                                let children_6;
                                const common$0027 = map_2((diff) => diff.stock, bind((whDiff_1) => tryFind((d) => (d.bottleName === bottleName), whDiff_1.commonData), bind((whDiffs) => tryFind((whDiff) => equals(whDiff.warehouse, warehouseName), whDiffs), whDiffs$0027)));
                                const diff$0027 = bind((diff_1) => diff_1.leftStock, bind((whDiff_3) => tryFind((d_1) => (d_1.bottleName === bottleName), whDiff_3.diffData), bind((whDiffs_1) => tryFind((whDiff_2) => equals(whDiff_2.warehouse, warehouseName), whDiffs_1), whDiffs$0027)));
                                return singleton((children_6 = toList(delay(() => {
                                    if (common$0027 != null) {
                                        return append(singleton(count), delay(() => {
                                            let elms_1;
                                            return singleton((elms_1 = singleton_2(Fa_i(ofArray_1([new Fa_IconOption(11, ["fas fa-check"]), new Fa_IconOption(0, [new Fa_ISize(0, [])]), new Fa_IconOption(15, ["has-text-success"])]), [])), createElement("span", {
                                                className: "icon",
                                                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                                            })));
                                        }));
                                    }
                                    else if (diff$0027 != null) {
                                        const diffRefStock = diff$0027 | 0;
                                        return append(singleton(createElement("span", {
                                            children: [`${count}`],
                                        })), delay(() => append(singleton(createElement("span", {
                                            children: [" ("],
                                        })), delay(() => append(singleton(createElement("span", {
                                            className: "has-text-weight-bold has-text-danger",
                                            children: diffRefStock,
                                        })), delay(() => singleton(")")))))));
                                    }
                                    else {
                                        return append(singleton(count), delay(() => {
                                            let elms;
                                            return singleton((elms = singleton_2(Fa_i(ofArray_1([new Fa_IconOption(11, ["fas fa-question"]), new Fa_IconOption(0, [new Fa_ISize(0, [])]), new Fa_IconOption(15, ["has-text-info"])]), [])), createElement("span", {
                                                className: "icon",
                                                children: Interop_reactApi.Children.toArray(Array.from(elms)),
                                            })));
                                        }));
                                    }
                                })), createElement("td", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                                })));
                            }));
                        }));
                    })), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    }))) : empty_1();
                }, inventoryArray))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                })), (children_14 = singleton_2((children_12 = ofArray_1([createElement("th", {
                    children: ["Total"],
                }), createElement("th", {}), createElement("th", {
                    children: [int32ToString(sumBy((tupledArg_4) => tupledArg_4[1], inventoryArray, {
                        GetZero: () => 0,
                        Add: (x_8, y_6) => (x_8 + y_6),
                    }))],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                }))), createElement("tfoot", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))))), delay(() => singleton(createElement(BottleAnalysisModal, {
                    endDate: date,
                    bottleToAnalyze: unwrap(patternInput_1[0]),
                    closeModal: () => {
                        setBottleToAnalyze(void 0);
                    },
                }))));
            }));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_20);
}

export class WarehousesResponse extends Record {
    constructor(data) {
        super();
        this.data = data;
    }
}

export function WarehousesResponse_$reflection() {
    return record_type("CwwDashboard.Sections.Inventory.WarehousesResponse", [], WarehousesResponse, () => [["data", anonRecord_type(["warehouses", array_type(anonRecord_type(["id", int32_type], ["is_active", bool_type], ["name", string_type]))])]]);
}

export function useActiveWarehouses() {
    let matchValue;
    const patternInput = useGql("{\r\n        warehouses {\r\n          id\r\n          name\r\n          is_active\r\n        }\r\n      }");
    const content = patternInput[1];
    if (patternInput[0] ? true : (content === "")) {
        return new QueryResponse$1(0, []);
    }
    else {
        let parseResult;
        try {
            parseResult = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(content), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(WarehousesResponse_$reflection())) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())]));
        }
        catch (ex) {
            parseResult = (new FSharpResult$2(1, [ex.message]));
        }
        if (parseResult.tag === 1) {
            return new QueryResponse$1(2, [parseResult.fields[0]]);
        }
        else {
            return new QueryResponse$1(1, [parseResult.fields[0]]);
        }
    }
}

function InventoryByWarehouse(inventoryByWarehouseInputProps) {
    const wineBottles = inventoryByWarehouseInputProps.wineBottles;
    const whArray = inventoryByWarehouseInputProps.whArray;
    const date = inventoryByWarehouseInputProps.date;
    const whResponse = useActiveWarehouses();
    const isActiveWh = (whResponse.tag === 1) ? ((warehouseName) => whResponse.fields[0].data.warehouses.some((wh) => {
        if (wh.name === warehouseName) {
            return wh.is_active;
        }
        else {
            return false;
        }
    })) : ((_arg) => false);
    const xs_7 = toList(delay(() => append(singleton(createElement("h1", createObj(Helpers_combineClasses("title", ofArray_1([["className", "is-4"], ["children", "Inventory by Warehouse"]]))))), delay(() => {
        let matchValue_2;
        return (whResponse.tag === 0) ? singleton(createElement(LoadingElement, null)) : append((matchValue_2 = whResponse, (matchValue_2.tag === 2) ? singleton(createElement("span", {
            className: "tag",
            children: `Couldn't fetch warehouses: (${matchValue_2.fields[0]}), Some inactive warehouses may be displayed`,
        })) : (empty_1())), delay(() => {
            let array_2;
            return collect_1((tupledArg_1) => {
                let elems;
                const warehouseName_2 = tupledArg_1[0];
                const inventory = tupledArg_1[1];
                return [createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray_1([["className", "is-6 is-flex is-justify-content-space-between is-align-items-center"], (elems = toList(delay(() => {
                    let children;
                    return append(singleton((children = toList(delay(() => append(singleton(createElement("b", {
                        children: [warehouseName_2],
                    })), delay(() => ((warehouseName_2 === "Main") ? singleton(createElement("span", createObj(Helpers_combineClasses("tag", ofArray_1([["className", "ml-3 is-dark"], ["children", "Main"]]))))) : empty_1()))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))), delay(() => {
                        let whId;
                        if (whResponse.tag === 1) {
                            whId = pick((wh_1) => {
                                if (wh_1.name === warehouseName_2) {
                                    return wh_1.id;
                                }
                                else {
                                    return void 0;
                                }
                            }, whResponse.fields[0].data.warehouses);
                        }
                        else {
                            throw new Error("error");
                        }
                        return singleton(createElement(CreateSnapshotButton, {
                            warehouseId: whId,
                            inventory: inventory,
                            wineBottles: wineBottles,
                        }));
                    }));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement(Inventory, {
                    date: date,
                    warehouseName: warehouseName_2,
                    inventory: inventory,
                    breakdownByPurchase: [],
                    wineBottles: wineBottles,
                    filterEmptyStocks: true,
                })];
            }, (array_2 = sortBy((input) => {
                const input_1 = input;
                if (input_1[0] === "Main") {
                    return [0, input_1];
                }
                else {
                    return [1, input_1];
                }
            }, toArray_1(whArray), {
                Compare: compareArrays,
            }), array_2.filter((tupledArg) => {
                if (sum(toArray(delay(() => collect((matchValue) => singleton(matchValue[1]), tupledArg[1]))), {
                    GetZero: () => 0,
                    Add: (x, y) => (x + y),
                }) > 0) {
                    return true;
                }
                else {
                    return isActiveWh(tupledArg[0]);
                }
            })));
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs_7);
}

function ConsolidatedInventory(consolidatedInventoryInputProps) {
    const wineBottles = consolidatedInventoryInputProps.wineBottles;
    const breakdownByPurchase = consolidatedInventoryInputProps.breakdownByPurchase;
    const consolidatedInventory = consolidatedInventoryInputProps.consolidatedInventory;
    const date = consolidatedInventoryInputProps.date;
    const xs_2 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray_1([["className", "is-4"], ["children", "Consolidated Inventory"]])))), createElement(Inventory, {
        date: date,
        inventory: consolidatedInventory,
        breakdownByPurchase: breakdownByPurchase,
        wineBottles: wineBottles,
        filterEmptyStocks: false,
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export class DisplayMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Consolidated", "ByWarehouse"];
    }
}

export function DisplayMode_$reflection() {
    return union_type("CwwDashboard.Sections.Inventory.DisplayMode", [], DisplayMode, () => [[], []]);
}

export function System_DateTime__DateTime_EndOfNthPreviousMonth_Z524259A4(x, n) {
    return endOfMonth(subMonths(x, n));
}

export function invalidateWarehouseMovements(year, month) {
    return singleton_1.Delay(() => {
        const invalidateUrl = toText(interpolate("%P()/invalidate/warehouse-movements/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year, month]));
        return singleton_1.Bind(Http_post(invalidateUrl, ""), (_arg) => singleton_1.Return(void 0));
    });
}

export function InventorySection() {
    let elems_10, elms_3;
    const monthM = endOfMonth(today_1());
    const patternInput = useFeliz_React__React_useState_Static_1505(new InventoryMode(1, []));
    const mode = patternInput[0];
    const monthList = [monthM, System_DateTime__DateTime_EndOfNthPreviousMonth_Z524259A4(monthM, 1), System_DateTime__DateTime_EndOfNthPreviousMonth_Z524259A4(monthM, 2), System_DateTime__DateTime_EndOfNthPreviousMonth_Z524259A4(monthM, 3), System_DateTime__DateTime_EndOfNthPreviousMonth_Z524259A4(monthM, 4)];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(monthM);
    const setCurrentDate = patternInput_1[1];
    const currentDate = patternInput_1[0];
    const date_2 = currentDate;
    const patternInput_2 = useGetQueryWithRefresh(useReact_useMemo_10C6A43C(() => (equals(mode, new InventoryMode(1, [])) ? toText(interpolate("%P()/global-inventory/end-of-day-new/%d%P()/%d%P()/%d%P()", [KPIS_ENDPOINT, year_1(currentDate), month_1(currentDate), day(currentDate)])) : toText(interpolate("%P()/global-inventory/end-of-day/%d%P()/%d%P()/%d%P()", [KPIS_ENDPOINT, year_1(currentDate), month_1(currentDate), day(currentDate)]))), [mode, currentDate]), () => invalidateWarehouseMovements(year_1(currentDate), month_1(currentDate)));
    const wineBottlesResponse = useWineBottles();
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(new DisplayMode(1, []));
    const setDisplayMode = patternInput_3[1];
    const displayMode = patternInput_3[0];
    return createElement("article", createObj(Helpers_combineClasses("message", ofArray_1([["className", "is-info"], (elems_10 = [createElement("div", {
        className: "message-header",
        children: Interop_reactApi.Children.toArray(["End of Month Inventory"]),
    }), (elms_3 = toList(delay(() => {
        let elems_1;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("buttons", ofArray_1([["className", "has-addons"], (elems_1 = toList(delay(() => map((m) => createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton(["key", format_3(m, "yyyy-MM")]), delay(() => append(singleton(["className", "is-small is-rounded"]), delay(() => append(equals_1(m, currentDate) ? singleton(["className", "is-active is-info"]) : empty_1(), delay(() => append(singleton(["onClick", (_arg) => {
            setCurrentDate(m);
        }]), delay(() => singleton(["children", format_3(m, "MMM yyyy")])))))))))))))), monthList))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))))), delay(() => {
            let elems_4, elems_2, elems_3;
            return append(singleton(createElement("div", createObj(ofArray_1([["style", {
                display: "flex",
                alignItems: "baseline",
            }], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("buttons", ofArray_1([["className", "has-addons"], (elems_2 = [createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton(["className", "is-small is-rounded"]), delay(() => append(equals(displayMode, new DisplayMode(0, [])) ? singleton(["className", "is-active is-info"]) : empty_1(), delay(() => append(singleton(["onClick", (_arg_1) => {
                setDisplayMode(new DisplayMode(0, []));
            }]), delay(() => singleton(["children", "consolidated"])))))))))))), createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton(["className", "is-small is-rounded"]), delay(() => append(equals(displayMode, new DisplayMode(1, [])) ? singleton(["className", "is-active is-info"]) : empty_1(), delay(() => append(singleton(["onClick", (_arg_2) => {
                setDisplayMode(new DisplayMode(1, []));
            }]), delay(() => singleton(["children", "by warehouse"]))))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("buttons", ofArray_1([["className", "ml-2 has-addons"], (elems_3 = toList(delay(() => map((m_1) => createElement("button", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton(["className", "is-small is-rounded"]), delay(() => append(equals(mode, m_1) ? singleton(["className", "is-active is-primary"]) : empty_1(), delay(() => append(singleton(["onClick", (_arg_3) => {
                patternInput[1](m_1);
            }]), delay(() => singleton(["children", toString_1(m_1)])))))))))))), [new InventoryMode(0, []), new InventoryMode(1, [])]))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray_1([["className", "is-small"], ["className", "ml-2 is-text"], ["onClick", (_arg_4) => {
                patternInput_2[2]();
            }], ["children", "reload"]]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                let elms_1, safeProps, arg_1, name_23, props_21, arg, name_22, props_20;
                return append(singleton((elms_1 = ofArray_1([createElement("label", {
                    className: "label",
                    children: "Date picker (inventory by end of selected date)",
                }), (safeProps = ((arg_1 = ["clearLabel", "Clear"], (name_23 = arg_1[0], (props_21 = ((arg = ["displayMode", new DisplayMode_1(0, [])], (name_22 = arg[0], (props_20 = ofArray_1([["dateOnly", true], ["onDateSelected", (d) => {
                    if (d != null) {
                        const dt_1 = d;
                        setCurrentDate(create(year_1(dt_1), month_1(dt_1), day(dt_1)));
                    }
                }], ["isRange", false], ["clearLabel", "Clear date"], ["closeOnSelect", true]]), exists((tupledArg) => (tupledArg[0] === name_22), map_4((value_1_1) => value_1_1, props_20)) ? props_20 : cons([name_22, arg[1]], props_20))))), exists((tupledArg_1) => (tupledArg_1[0] === name_23), map_4((value_1_2) => value_1_2, props_21)) ? props_21 : cons([name_23, arg_1[1]], props_21))))), createElement(DatePicker_dateTimePicker, {
                    p: createObj(safeProps),
                }))]), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => {
                    let elems_8;
                    return append(singleton(createElement("div", createObj(ofArray_1([["className", "mt-5 mb-6"], (elems_8 = [createElement("h1", createObj(Helpers_combineClasses("title", ofArray_1([["className", "is-5"], ["children", "Documents"]])))), createElement("a", createObj(Helpers_combineClasses("button", toList(delay(() => append(singleton(["className", "is-small"]), delay(() => append(singleton(["className", "is-outlined"]), delay(() => append(singleton(["href", toText(interpolate("%P()/documents/first-inventory-snapshot/month/%d%P()/%02d%P()", [KPIS_ENDPOINT, year_1(currentDate), month_1(currentDate)])) + "?detailLevel=Distributed"]), delay(() => append(singleton(["download", true]), delay(() => {
                        let elems_7;
                        return singleton((elems_7 = toList(delay(() => {
                            let elms_2;
                            return append(singleton((elms_2 = singleton_2(Fa_i(singleton_2(new Fa_IconOption(11, ["fas fa-file-excel"])), [])), createElement("span", {
                                className: "icon",
                                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                            }))), delay(() => {
                                let children_3;
                                return singleton((children_3 = ofArray_1(["1st Inventory Snapshot of", createElement("b", {
                                    children: [` ${format_3(date_2, "MM/yyyy (MMMM)")}`],
                                }), " (XLS)"]), createElement("span", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                                })));
                            }));
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]));
                    })))))))))))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                        let res;
                        let matchResult;
                        if (patternInput_2[0]) {
                            matchResult = 0;
                        }
                        else {
                            switch (wineBottlesResponse.tag) {
                                case 1: {
                                    matchResult = 1;
                                    break;
                                }
                                case 2: {
                                    matchResult = 2;
                                    break;
                                }
                                default:
                                    matchResult = 0;
                            }
                        }
                        switch (matchResult) {
                            case 0:
                                return singleton(createElement(LoadingElement, null));
                            case 1: {
                                const wineBottles = wineBottlesResponse.fields[0];
                                return singleton((res = Result_Map((response) => {
                                    const xs_26 = toList(delay(() => (equals(displayMode, new DisplayMode(0, [])) ? singleton(createElement(ConsolidatedInventory, {
                                        date: date_2,
                                        consolidatedInventory: response.consolidatedInventory,
                                        breakdownByPurchase: response.breakdownByPurchase,
                                        wineBottles: wineBottles,
                                    })) : singleton(createElement(InventoryByWarehouse, {
                                        date: date_2,
                                        whArray: response.distributedInventory,
                                        wineBottles: wineBottles,
                                    })))));
                                    return react.createElement(react.Fragment, {}, ...xs_26);
                                }, (() => {
                                    let matchValue_1;
                                    try {
                                        return new FSharpResult$2(0, [(matchValue_1 = SimpleJson_tryParse(patternInput_2[1]), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(Types_GlobalInventoryResponse_$reflection())) : (() => {
                                            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                                        })())]);
                                    }
                                    catch (ex) {
                                        return new FSharpResult$2(1, [ex.message]);
                                    }
                                })()), Result_defaultValue(defaultOf_1(), res)));
                            }
                            default:
                                return singleton(createElement("span", {
                                    className: "tag",
                                    children: `Error fetching wine bottles: ${wineBottlesResponse.fields[0]}`,
                                }));
                        }
                    }));
                }));
            }));
        }));
    })), createElement("div", {
        className: "message-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))));
}

