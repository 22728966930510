import { Union, Record } from "./fable_modules/fable-library.4.3.0/Types.js";
import { union_type, record_type, array_type, decimal_type, option_type, anonRecord_type, string_type } from "./fable_modules/fable-library.4.3.0/Reflection.js";
import { op_Modulus, equals as equals_1, fromParts } from "./fable_modules/fable-library.4.3.0/Decimal.js";
import { createObj, equals, defaultOf } from "./fable_modules/fable-library.4.3.0/Util.js";
import { defaultArg, unwrap } from "./fable_modules/fable-library.4.3.0/Option.js";
import { CMS_ENDPOINT, IS_OFFLINE } from "./Common.fs.js";
import { useGql, QueryResponse$1 } from "./Hooks.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.4.3.0/Choice.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { empty, collect, singleton, append, delay, toList } from "./fable_modules/fable-library.4.3.0/Seq.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.3.0.0/./ElementBuilders.fs.js";
import { day, month, year, today as today_1 } from "./fable_modules/fable-library.4.3.0/Date.js";
import { interpolate, toText } from "./fable_modules/fable-library.4.3.0/String.js";
import { singleton as singleton_1, ofArray } from "./fable_modules/fable-library.4.3.0/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/./Interop.fs.js";

export class WineBottlesResponse extends Record {
    constructor(data) {
        super();
        this.data = data;
    }
}

export function WineBottlesResponse_$reflection() {
    return record_type("Catalog.WineBottlesResponse", [], WineBottlesResponse, () => [["data", anonRecord_type(["wine_bottles", array_type(anonRecord_type(["description", string_type], ["domain", anonRecord_type(["country", string_type], ["name", string_type])], ["exclusivity", string_type], ["name", string_type], ["picture", option_type(anonRecord_type(["id", string_type]))], ["price_consumer", option_type(decimal_type)], ["price_supplier", option_type(decimal_type)], ["vintage", string_type]))])]]);
}

export const mockWineBottles = new WineBottlesResponse({
    wine_bottles: [(() => {
        const domain = {
            country: "France",
            name: "Rémi Poujol",
        };
        const picture = {
            id: "myid",
        };
        const price_supplier = fromParts(2056, 0, 0, false, 2);
        const price_consumer = fromParts(20, 0, 0, false, 0);
        return {
            description: "C\'est le temps qui fait tout",
            domain: domain,
            exclusivity: defaultOf(),
            name: "Le Temps Fait Tout",
            picture: unwrap(picture),
            price_consumer: unwrap(price_consumer),
            price_supplier: unwrap(price_supplier),
            vintage: "2018",
        };
    })(), (() => {
        const domain_1 = {
            country: "France",
            name: "Vigneron Bien Fêteur",
        };
        const picture_1 = {
            id: "myid",
        };
        const price_supplier_1 = fromParts(38, 0, 0, false, 0);
        const price_consumer_1 = fromParts(38, 0, 0, false, 0);
        return {
            description: "C\'est le temps qui fait tout",
            domain: domain_1,
            exclusivity: defaultOf(),
            name: "Esprit Vendangeur",
            picture: unwrap(picture_1),
            price_consumer: unwrap(price_consumer_1),
            price_supplier: unwrap(price_supplier_1),
            vintage: "2020",
        };
    })()],
});

export class CatalogType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Consumer", "GeneralSupplier", "SpecificSupplier"];
    }
}

export function CatalogType_$reflection() {
    return union_type("Catalog.CatalogType", [], CatalogType, () => [[], [], [["clientName", string_type]]]);
}

export function useWineBottles(catalogType) {
    let matchValue;
    if (IS_OFFLINE) {
        return new QueryResponse$1(1, [mockWineBottles]);
    }
    else {
        const patternInput = useGql(((("\r\n        {\r\n          wine_bottles(\r\n            filter: {\r\n        " + (equals(catalogType, new CatalogType(0, [])) ? "\r\n        disabled_for_supplier: { _eq: false },\r\n        " : "\r\n        disabled_for_consumer: { _eq: false },\r\n        ")) + "\r\n              discontinued: { _eq: false }\r\n            },\r\n            sort: ") + (equals(catalogType, new CatalogType(0, [])) ? "\"price_consumer\"" : "\"price_supplier\"")) + " \r\n          ) {\r\n            id\r\n            name\r\n            vintage\r\n            domain {\r\n              id\r\n              name\r\n              country\r\n            }\r\n            picture {\r\n              id\r\n              title\r\n            }\r\n            description\r\n            price_supplier\r\n            price_consumer\r\n            exclusivity\r\n          }\r\n        }\r\n        ");
        const content = patternInput[1];
        if (patternInput[0] ? true : (content === "")) {
            return new QueryResponse$1(0, []);
        }
        else {
            let matchValue_1;
            try {
                matchValue_1 = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(content), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(WineBottlesResponse_$reflection())) : (() => {
                    throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
                })())]));
            }
            catch (ex) {
                matchValue_1 = (new FSharpResult$2(1, [ex.message]));
            }
            if (matchValue_1.tag === 1) {
                return new QueryResponse$1(2, [matchValue_1.fields[0]]);
            }
            else {
                return new QueryResponse$1(1, [matchValue_1.fields[0]]);
            }
        }
    }
}

export function getAssetUrl(assetId) {
    if (IS_OFFLINE) {
        return "img/esprit-vendangeur-2017.jpg";
    }
    else {
        return `${CMS_ENDPOINT}/assets/${assetId}?width=480&fit=inside`;
    }
}

export function Catalog(catalogInputProps) {
    const catalogType = catalogInputProps.catalogType;
    const wineBottlesResponse = useWineBottles(catalogType);
    const xs_21 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("container", ofArray([["className", "is-fluid"], (elems = toList(delay(() => append(singleton(createElement("h1", createObj(Helpers_combineClasses("title", toList(delay(() => append(singleton(["className", "is-2 my-5 has-text-centered"]), delay(() => {
            const matchValue = catalogType;
            switch (matchValue.tag) {
                case 1:
                case 2:
                    return singleton(["children", "Supplier Catalog"]);
                default:
                    return singleton(["children", "Consumer Catalog"]);
            }
        })))))))), delay(() => {
            const today = today_1();
            return singleton(createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "is-4 my-5 has-text-centered"], ["children", toText(interpolate("%P()-%02d%P()-%02d%P()", [year(today), month(today), day(today)]))]])))));
        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))), delay(() => {
            let elems_6;
            const matchValue_1 = wineBottlesResponse;
            return (matchValue_1.tag === 2) ? singleton(`Error: ${matchValue_1.fields[0]}`) : ((matchValue_1.tag === 1) ? singleton(createElement("div", createObj(Helpers_combineClasses("container", ofArray([["className", "px-5 pt-5"], (elems_6 = toList(delay(() => collect((wine) => {
                let elems_5, elems_1, elms_1, elms, elems_2, children;
                return (((wine.exclusivity !== defaultOf()) === false) ? true : ((catalogType.tag === 1) ? false : ((catalogType.tag === 2) && (wine.exclusivity.indexOf(catalogType.fields[0]) >= 0)))) ? singleton(createElement("div", createObj(Helpers_combineClasses("columns", singleton_1((elems_5 = [createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-narrow is-flex is-justify-content-center"], (elems_1 = toList(delay(() => {
                    const matchValue_2 = wine.picture;
                    if (matchValue_2 == null) {
                        return empty();
                    }
                    else {
                        const picture = matchValue_2;
                        return singleton(createElement("img", {
                            style: {
                                minWidth: 150,
                                marginBottom: "auto",
                            },
                            src: getAssetUrl(picture.id),
                            width: 150,
                        }));
                    }
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), (elms_1 = singleton_1((elms = ofArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "has-text-centered-mobile"], (elems_2 = [createElement("div", {
                    children: [`${wine.name} ${wine.vintage}`],
                }), (children = singleton_1(createElement("i", {
                    children: [`${wine.domain.name} (${wine.domain.country})`],
                })), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("column", toList(delay(() => ((wine.description !== defaultOf()) ? singleton(["dangerouslySetInnerHTML", {
                    __html: `${wine.description}`,
                }]) : singleton(["children", ""]))))))), createElement("div", createObj(Helpers_combineClasses("column", toList(delay(() => append(singleton(["className", "px-3 content has-text-centered-mobile has-text-right-tablet is-1"]), delay(() => {
                    const price = (catalogType.tag === 0) ? wine.price_consumer : wine.price_supplier;
                    return equals_1(op_Modulus(defaultArg(price, fromParts(0, 0, 0, false, 0)), fromParts(1, 0, 0, false, 0)), fromParts(0, 0, 0, false, 0)) ? singleton(["children", `$${defaultArg(price, fromParts(0, 0, 0, false, 0))}`]) : singleton(["children", toText(interpolate("$%.2f%P()", [defaultArg(price, fromParts(0, 0, 0, false, 0))]))]);
                })))))))]), createElement("div", {
                    className: "columns",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])))))) : empty();
            }, matchValue_1.fields[0].data.wine_bottles))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))))) : singleton("Loading..."));
        }));
    }));
    return react.createElement(react.Fragment, {}, ...xs_21);
}

